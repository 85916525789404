import { push, CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'connected-react-router';
import routes from '../../constants/routes';
import _find from 'lodash/find';
import _get from 'lodash/get';

const findRouteDetails = path => _find(routes, route => route.path === path) || {};

export default ({ getState, dispatch }) => next => action => {
    if ([CALL_HISTORY_METHOD, LOCATION_CHANGE].includes(action.type)) {
        const path = _get(action, 'payload.args[0]') || _get(action, 'payload.location.pathname');
        const { requiresAuth } = findRouteDetails(path);
        const userUnauthorised = getState().systemReducer.signedIn === false;
        if (requiresAuth && userUnauthorised) {
            return dispatch(push(`${routes.SIGN_IN.path}?successRoute=${path}`));
        }
    }
    return next(action);
};
