import Store from '../../data/store';
import { restaurantActions, systemActions } from '../actions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import { combineReducers } from 'redux';

const initialState = {
    restaurants: [],
    remotingGet: { busy: false, message: null },
};

export const restaurants = (state = initialState.restaurants, action = {}) => {
    switch (action.type) {
        case restaurantActions.GET_NEARBY_RESTAURANTS_RESPONSE:
        case restaurantActions.GET_RESTAURANTS_RESPONSE:
            return action.response.map(s => Store.fromJson(s));
        case restaurantActions.GET_RESTAURANTS:
            return initialState.restaurants;
        default:
            return state;
    }
};

export const remotingGet = (state = initialState.remotingGet, action = {}) => {
    switch (action.type) {
        case restaurantActions.GET_RESTAURANTS:
        case restaurantActions.GET_NEARBY_RESTAURANTS:
            return remotingUtilities.getBusyState();
        case restaurantActions.GET_NEARBY_RESTAURANTS_RESPONSE:
        case restaurantActions.GET_RESTAURANTS_RESPONSE:
        case systemActions.RESET_REMOTING_STATE:
            return remotingUtilities.resetRemotingState();
        case restaurantActions.GET_RESTAURANTS_ERROR:
        case restaurantActions.GET_NEARBY_RESTAURANTS_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export default combineReducers({
    restaurants,
    remotingGet,
});
