import Urls from '../../constants/urls';
import * as bookingActions from '../actions/bookingActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === bookingActions.CANCEL_BOOKING) {
        const url = `${Urls.CANCEL_BOOKING}/${action.booking.reservationId}`;
        try {
            const response = await axios.post(url, {});

            dispatch(bookingActions.cancelBookingResponse(response.data, action.booking));
            return dispatch(bookingActions.getBookings());
        } catch (error) {
            Logger.error(url, error.response);
            return dispatch(bookingActions.cancelBookingError(error.response));
        }
    }
};
