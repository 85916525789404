import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const BusyIndicator = ({ inline = false, text }) => {
    const renderTable = () => {
        return (
            <Container className={'busy-indicator' + (inline ? '' : ' busy-dialog')}>
                <Grid
                    className="busy-indicator-grid"
                    container
                    wrap="nowrap"
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item>
                        <CircularProgress color="primary" />
                    </Grid>
                    {text && (
                        <Grid item>
                            <Typography variant="subtitle1">{text}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>
        );
    };

    if (inline) {
        return renderTable();
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open>
            <DialogContent dividers>{renderTable()}</DialogContent>
        </Dialog>
    );
};

BusyIndicator.propTypes = {
    inline: PropTypes.bool,
    text: PropTypes.string,
};

export default BusyIndicator;
