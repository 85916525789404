const routes = {
    HOME: {
        path: '/',
    },
    SIGN_IN: {
        path: '/table-order/sign-in',
        pageHasBackButton: true,
    },
    RESET_PASSWORD: {
        path: '/table-order/reset-password',
    },
    SIGNUP: {
        path: '/table-order/sign-up',
        pageHasBackButton: true,
    },
    SELECT_RESTAURANT: {
        path: '/table-order/select-restaurant',
        pageHasBackButton: true,
    },
    RESTAURANT: {
        path: '/table-order/restaurant',
        pageHasBackButton: true,
    },
    JOIN_TABLE: {
        path: '/table-order/join-table',
        requiresAuth: true,
        pageHasBackButton: true,
    },
    TABLE_CODE: {
        requiresAuth: true,
        path: '/table-order/table-code',
    },
    MENU: {
        path: '/table-order/menu',
        requiresAuth: true,
        pageHasMenuButton: true,
        pageHasBasket: true,
    },
    MENU_PREVIEW: {
        path: '/table-order/menu-preview',
        pageHasBackButton: true,
    },
    MENU_ITEM_OPTIONS: {
        requiresAuth: true,
        path: '/table-order/menu-item-options',
        pageHasBackButton: true,
    },
    BASKET: {
        requiresAuth: true,
        path: '/table-order/basket',
        pageHasBackButton: true,
        pageNeedsLatestCheck: true,
        pageHasBasket: true,
    },
    CHECKOUT: {
        requiresAuth: true,
        path: '/table-order/checkout',
        pageHasBackButton: true,
        pageNeedsLatestCheck: true,
    },
    CONFIRMATION: {
        requiresAuth: true,
        path: '/table-order/confirmation',
    },
    SELECT_CARD: {
        requiresAuth: true,
        path: '/table-order/select-card',
        pageHasBackButton: true,
    },
    SPLIT_CHECK: {
        requiresAuth: true,
        path: '/table-order/split-check',
        pageHasBackButton: true,
    },
    ORDER_ACCEPTED: {
        requiresAuth: true,
        path: '/table-order/order-accepted',
        pageNeedsLatestCheck: true,
    },
    TABLE_USER_STATUS: {
        requiresAuth: true,
        path: '/table-order/table-user-status',
        pageHasBackButton: true,
        pageNeedsLatestCheck: true,
        pageHasBasket: true,
    },
};

export default routes;
