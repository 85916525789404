export const RESET_REMOTING_STATE = 'MWEB/RESET_REMOTING_STATE_RESTAURANTS';
export const GET_RESTAURANT_EXISTANCE = 'MWEB/GET_RESTAURANT_EXISTANCE';
export const GET_RESTAURANT_EXISTANCE_RESPONSE = 'MWEB/GET_RESTAURANT_EXISTANCE_RESPONSE';
export const LOAD_RESTAURANTS = 'MWEB/LOAD_RESTAURANTS';
export const GET_RESTAURANTS = 'MWEB/GET_RESTAURANTS';
export const GET_RESTAURANTS_RESPONSE = 'MWEB/GET_RESTAURANTS_RESPONSE';
export const GET_RESTAURANTS_ERROR = 'MWEB/GET_RESTAURANTS_ERROR';
export const GET_RESTAURANT = 'MWEB/GET_RESTAURANT';
export const GET_RESTAURANT_RESPONSE = 'MWEB/GET_RESTAURANT_RESPONSE';
export const GET_RESTAURANT_ERROR = 'MWEB/GET_RESTAURANT_ERROR';
export const TOGGLE_FAVOURITE = 'MWEB/TOGGLE_FAVOURITE';
export const SET_FILTER = 'MWEB/SET_FILTER';
export const SELECT_RESTAURANT = 'MWEB/SELECT_RESTAURANT_RA';
export const SEARCH_RESTAURANTS = 'MWEB/SEARCH_RESTAURANTS';
export const SEARCH_RESTAURANTS_RESPONSE = 'MWEB/SEARCH_RESTAURANTS_RESPONSE';
export const SEARCH_RESTAURANTS_ERROR = 'MWEB/SEARCH_RESTAURANTS_ERROR';

export const getRestaurantExistance = () => ({ type: GET_RESTAURANT_EXISTANCE });

export const getRestaurantExistanceResponse = () => ({ type: GET_RESTAURANT_EXISTANCE_RESPONSE });

export const resetRemotingState = () => ({ type: RESET_REMOTING_STATE });

export const loadRestaurants = () => ({ type: LOAD_RESTAURANTS });

export const getRestaurants = (location, page = 1, pageSize = 10) => ({
    type: GET_RESTAURANTS,
    location,
    page,
    pageSize,
});

export const getRestaurantsResponse = (response, page = 1, pageSize = 10) => ({
    type: GET_RESTAURANTS_RESPONSE,
    response,
    page,
    pageSize,
});

export const getRestaurantsError = (error, page) => ({
    type: GET_RESTAURANTS_ERROR,
    error,
    page,
});

export const getRestaurant = restaurantId => ({
    type: GET_RESTAURANT,
    restaurantId,
});

export const getRestaurantResponse = (response, restaurant) => ({
    type: GET_RESTAURANT_RESPONSE,
    response,
    restaurant,
});

export const getRestaurantError = error => ({
    type: GET_RESTAURANT_ERROR,
    error,
});

export const toggleFavourite = id => ({
    type: TOGGLE_FAVOURITE,
    id,
});

export const setFilter = filter => ({
    type: SET_FILTER,
    filter,
});

export const selectRestaurant = restaurantId => ({
    type: SELECT_RESTAURANT,
    restaurantId,
});

export const searchRestaurants = searchString => ({
    type: SEARCH_RESTAURANTS,
    searchString,
});

export const searchRestaurantsResponse = response => ({
    type: SEARCH_RESTAURANTS_RESPONSE,
    response,
});

export const searchRestaurantsError = error => ({
    type: SEARCH_RESTAURANTS_ERROR,
    error,
});
