import { createStore, compose, combineReducers } from 'redux';
// import { createLogger } from 'redux-logger';
import systemReducer from './reducers/systemReducer';
import guestReducer from './reducers/guestReducer';
import restaurantReducer from './reducers/restaurantReducer';
import availabilityReducer from './reducers/availabilityReducer';
import bookingReducer from './reducers/bookingReducer';
import uiReducer from './reducers/uiReducer';
import locationReducer from './reducers/locationReducer';
import loadRestaurantsMiddleware from './middleware/loadRestaurantsMiddleware';
import selectRestaurantMiddleware from './middleware/selectRestaurantMiddleware';
import selectSlotMiddleware from './middleware/selectSlotMiddleware';
import updateGuestDetailsService from './middleware/updateGuestDetails';
import getGuestDetailsService from './middleware/getGuestDetails';
import getRestaurantsService from './middleware/getRestaurants';
import getExtraReservationFields from './middleware/getExtraReservationFields';
import getRestaurantService from './middleware/getRestaurant';
import availabilityService from './middleware/availabilityService';
import createBookingService from './middleware/createBooking';
import cancelBookingService from './middleware/cancelBooking';
import getBookingsService from './middleware/getBookings';
import updateBookingService from './middleware/updateBooking';
import locationService from './middleware/locationService';
import deepLinkRedirect from './middleware/deepLinkRedirect';
import googleAnalyticsMiddleware from './middleware/googleAnalyticsMiddleware';
import getInitialLocationMiddleware from './middleware/getInitialLocationMiddleware';
import submitBookingForm from './middleware/submitBookingForm';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
    systemReducer,
    guestReducer,
    restaurantReducer,
    availabilityReducer,
    bookingReducer,
    uiReducer,
    locationReducer,
});

export const middlewares = [
    // createLogger(),
    googleAnalyticsMiddleware,
    getInitialLocationMiddleware,
    createBookingService,
    submitBookingForm,
    getBookingsService,
    cancelBookingService,
    updateBookingService,
    updateGuestDetailsService,
    getGuestDetailsService,
    getRestaurantsService,
    getRestaurantService,
    getExtraReservationFields,
    availabilityService,
    loadRestaurantsMiddleware,
    selectRestaurantMiddleware,
    selectSlotMiddleware,
    locationService,
    deepLinkRedirect,
];

export default function configureStore(initialState) {
    return createStore(rootReducer, initialState, composeEnhancers(middlewares));
}
