import Urls from '../../constants/urls';
import * as restaurantActions from '../actions/restaurantActions';
import axios from 'axios';
import Logger from '../../utilities/logger';
import { MAX_SEARCH_RADIUS_MILES, MARRIOT_BRAND_ID } from '../../constants/constants';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === restaurantActions.GET_RESTAURANTS) {
        const { location, pageSize, page } = action;
        const queryString = `?brandid=${MARRIOT_BRAND_ID}&maxradiusmiles=${MAX_SEARCH_RADIUS_MILES}`;
        const url = `${Urls.GET_RESTAURANTS_BY_LOCATION}${location.longitude}/${location.latitude}/${pageSize}/${page}${queryString}`;
        try {
            const response = await axios.get(url);
            return dispatch(restaurantActions.getRestaurantsResponse(response.data, page, pageSize));
        } catch (error) {
            Logger.error(url, error.response);
            return dispatch(restaurantActions.getRestaurantsError(error.response, page));
        }
    }
};
