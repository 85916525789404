export default class Guest {
    static NOTIFICATION_TEXT = 2;
    static NOTIFICATION_EMAIL = 1;

    constructor(guest) {
        if (guest) {
            this.guestId = guest.guestId || -1;
            this.firstName = guest.firstName || '';
            this.lastName = guest.lastName || '';
            this.phoneNumber = guest.phoneNumber || '';
            this.emailAddress = guest.emailAddress || '';
            this.preferredContactMethod = guest.preferredContactMethod || Guest.NOTIFICATION_TEXT;
        }
    }

    clone() {
        return Guest.fromJson(this);
    }

    update(o) {
        this.firstName = o.firstName;
        this.lastName = o.lastName;
        this.phoneNumber = o.phoneNumber;
        this.emailAddress = o.email;
    }

    toSaveObject() {
        return {
            //guestId: this.guestId,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            preferredContactMethod: this.preferredContactMethod,
        };
    }

    toString() {
        return JSON.stringify(this.toSaveObject());
    }

    compare(guest) {
        const same = guest.toString() === this.toString();
        return same;
    }

    static create() {
        return Guest.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Guest(json);
    }
}
