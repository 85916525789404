import React from 'react';

export default class Restaurant {
    constructor(restaurant) {
        this.restaurantId = restaurant.restaurantId || 0;
        this.restaurantName = restaurant.restaurantName || '';
        this.addressLine1 = restaurant.addressLine1 || '';
        this.addressLine2 = restaurant.addressLine2 || '';
        this.city = restaurant.city || '';
        this.postCode = restaurant.postCode || '';
        this.openingTimes = restaurant.openingTimes || '';
        this.dressCode = restaurant.dressCode || '';
        this.paymentMethod = restaurant.paymentMethod || '';
        this.diningStyle = restaurant.diningStyle || '';
        this.pricingRangeId = parseInt(restaurant.pricingRangeId, 10) || 1;
        this.description = restaurant.description || '';
        this.tagIds = restaurant.tagIds || [];
        this.email = restaurant.email || '';
        this.distance = restaurant.distance || null;
        this.phone = restaurant.phone || '';
        this.bannerImageUrl = restaurant.bannerImageUrl || null;
        this.listingImageUrl = restaurant.listingImageUrl || null;
        this.logoUrl = restaurant.logoUrl || null;
        this.galleryImageUrls = restaurant.galleryImageUrls || [];
        this.cuisineType = restaurant.cuisineType || '';
        this.parking = restaurant.parking || '';
        this.shiftTypeIds = restaurant.shiftTypeIds || [];
        this.currencySymbol = restaurant.currencySymbol || '';
        this.hotelLatitude = restaurant.hotelLatitude || 0;
        this.hotelLongitude = restaurant.hotelLongitude || 0;
        this.website = restaurant.website || '';
        this.preReservationText = restaurant.preReservationText || null;
    }

    id() {
        return this.restaurantId;
    }

    getName() {
        return this.restaurantName;
    }

    getBannerImageUrl() {
        return this.bannerImageUrl;
    }

    getListingImageUrl() {
        if (this.listingImageUrl) return this.listingImageUrl;
        if (this.galleryImageUrls.length) return this.galleryImageUrls[0];
        return this.bannerImageUrl;
    }

    getLogoUrl() {
        return this.logoUrl;
    }

    getPriceRange() {
        if (!this.currencySymbol) return <span />;
        return [1, 2, 3, 4].map((id, index) => (
            <span className={this.pricingRangeId >= id ? 'set' : ''} key={`price-range-${index}`}>
                {this.currencySymbol}
            </span>
        ));
    }

    // iHasLatLng
    get latitude() {
        return this.hotelLatitude;
    }

    get longitude() {
        return this.hotelLongitude;
    }

    getLatLng() {
        if (this.hotelLatitude !== 0 && this.hotelLongitude !== 0) {
            return {
                lat: this.hotelLatitude,
                lng: this.hotelLongitude,
            };
        }
        return null;
    }

    getDistance() {
        if (!this.distance) return <span />;
        return (
            <span>
                <span className={'fas fa-map-marker-alt'} />
                &nbsp;{this.distance}&nbsp;{'mi'}
            </span>
        );
    }

    hasPromotions(availabilityMap, time = -1) {
        try {
            if (availabilityMap && availabilityMap.has(this.id())) {
                return availabilityMap.get(this.id()).hasPromotions(time);
            }
        } catch (error) {
            console.warn(error);
        }
        return false;
    }

    getPromotions(availabilityMap, time = -1) {
        try {
            if (availabilityMap && availabilityMap.has(this.id())) {
                return availabilityMap.get(this.id()).promotions;
            }
        } catch (error) {
            console.warn(error);
        }
        return [];
    }

    getAvailability(availabilityMap) {
        try {
            if (availabilityMap && availabilityMap.has(this.id())) {
                return availabilityMap.get(this.id());
            }
        } catch (error) {
            console.warn(error);
        }
        return null;
    }

    clone() {
        return Restaurant.fromJson(this);
    }

    static create() {
        return Restaurant.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Restaurant(json);
    }
}
