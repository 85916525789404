import Restaurant from '../../data/restaurant';
import RestaurantFilter from '../../data/restaurantFilter';
import * as restaurantActions from '../actions/restaurantActions';
import * as uiActions from '../actions/uiActions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import * as storage from '../../utilities/storage';

const initialState = {
    page: 1,
    pageSize: 10,
    canLoadMorePages: true,
    restaurantsUnfiltered: [], // server response
    favourites: storage.getFavouriteRestaurants(),
    filter: RestaurantFilter.create(),
    restaurants: [], // filtered restaurant list for display
    checkingRestaurantExistance: false,
    restaurant: null,
    remotingLoad: { busy: false, error: null },
    restaurantLoad: { busy: false, error: null },

    restaurantSearchResult: [],
    remotingRestaurantSearch: [],
};

function getRestaurants(state, action) {
    return Object.assign({}, state, {
        remotingLoad: remotingUtilities.getBusyState(),
    });
}

function getRestaurantExistance(state) {
    return { ...state, checkingRestaurantExistance: true };
}

function getRestaurantExistanceResponse(state) {
    return { ...state, checkingRestaurantExistance: false };
}

function getRestaurantsResponse(state, action) {
    let restaurants = [];
    let restaurantsUnfiltered = action.page === 1 ? [] : state.restaurantsUnfiltered.map(r => r.clone());
    let canLoadMorePages = state.canLoadMorePages;
    let pageSize = state.pageSize;
    try {
        if (action.response === '') {
            canLoadMorePages = false;
        } else {
            if (action.response.length < action.pageSize) canLoadMorePages = false;
            restaurantsUnfiltered = restaurantsUnfiltered.concat(action.response.map(r => Restaurant.fromJson(r)));
            if (action.page === 1) pageSize = action.pageSize;
        }
        restaurants = state.filter.filterRestaurantList(restaurantsUnfiltered, state.favourites);
    } catch (error) {
        console.log(error);
    }

    return Object.assign({}, state, {
        restaurants,
        restaurantsUnfiltered,
        remotingLoad: remotingUtilities.resetRemotingState(),
        page: action.page,
        pageSize,
        canLoadMorePages,
    });
}

function getRestaurantsError(state, action) {
    return Object.assign({}, state, {
        remotingLoad: remotingUtilities.getErrorState(action.error),
    });
}

function selectSlot(state, action) {
    return Object.assign({}, state, {
        restaurant: action.restaurant.clone(),
    });
}

function getRestaurant(state, action) {
    return Object.assign({}, state, {
        restaurant: null,
        restaurantLoad: remotingUtilities.getBusyState(),
    });
}

function getRestaurantResponse(state, action) {
    let restaurant = null;
    try {
        restaurant = Restaurant.fromJson(action.response);
        // wtf server returns null for distance, currencySymbol
        if (state.restaurant) {
            restaurant.distance = state.restaurant.distance;
            restaurant.currencySymbol = state.restaurant.currencySymbol;
        }
    } catch (error) {
        console.log(error);
    }

    return Object.assign({}, state, {
        restaurant,
        restaurantLoad: remotingUtilities.resetRemotingState(),
    });
}

function getRestaurantError(state, action) {
    return Object.assign({}, state, {
        restaurantLoad: remotingUtilities.getErrorState(action.error),
    });
}

function toggleFavourite(state, action) {
    let favourites = state.favourites;
    try {
        let id = parseInt(action.id, 10);
        if (favourites.indexOf(id) === -1) {
            favourites = favourites.map(id2 => id2);
            favourites.push(id);
        } else {
            favourites = favourites.filter(id2 => id2 !== id);
        }
        storage.setFavouriteRestaurants(favourites);
    } catch (error) {
        console.log(error);
    }
    return Object.assign({}, state, { favourites });
}

function setFilter(state, action) {
    let restaurants = [];
    let filter = state.filter;
    try {
        filter = action.filter.clone();
        restaurants = filter.filterRestaurantList(state.restaurantsUnfiltered, state.favourites);
    } catch (error) {
        console.log(error);
    }

    return Object.assign({}, state, { restaurants, filter });
}

function searchRestaurants(state, action) {
    return Object.assign({}, state, { remotingRestaurantSearch: remotingUtilities.getBusyState() });
}

function searchRestaurantsResponse(state, action) {
    let restaurantSearchResult = [];
    try {
        restaurantSearchResult = action.response.map(r => Restaurant.fromJson(r));
    } catch (error) {
        console.warn(error);
    }
    return Object.assign({}, state, {
        restaurantSearchResult,
        remotingRestaurantSearch: remotingUtilities.getRemotingState(),
    });
}

function searchRestaurantsError(state, action) {
    return Object.assign({}, state, { remotingRestaurantSearch: remotingUtilities.getErrorState(action.error) });
}

export default function restaurantReducer(state = initialState, action = {}) {
    switch (action.type) {
        case restaurantActions.GET_RESTAURANTS:
            return getRestaurants(state, action);
        case restaurantActions.GET_RESTAURANTS_RESPONSE:
            return getRestaurantsResponse(state, action);
        case restaurantActions.GET_RESTAURANTS_ERROR:
            return getRestaurantsError(state, action);
        case restaurantActions.GET_RESTAURANT_EXISTANCE:
            return getRestaurantExistance(state, action);
        case restaurantActions.GET_RESTAURANT_EXISTANCE_RESPONSE:
            return getRestaurantExistanceResponse(state, action);
        case uiActions.SELECT_SLOT:
            return selectSlot(state, action);

        case restaurantActions.GET_RESTAURANT:
            return getRestaurant(state, action);
        case restaurantActions.GET_RESTAURANT_RESPONSE:
            return getRestaurantResponse(state, action);
        case restaurantActions.GET_RESTAURANT_ERROR:
            return getRestaurantError(state, action);

        case restaurantActions.TOGGLE_FAVOURITE:
            return toggleFavourite(state, action);
        case restaurantActions.SET_FILTER:
            return setFilter(state, action);

        case restaurantActions.SEARCH_RESTAURANTS:
            return searchRestaurants(state, action);
        case restaurantActions.SEARCH_RESTAURANTS_RESPONSE:
            return searchRestaurantsResponse(state, action);
        case restaurantActions.SEARCH_RESTAURANTS_ERROR:
            return searchRestaurantsError(state, action);

        default:
            return state;
    }
}
