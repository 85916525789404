import Urls from '../../constants/urls';
import * as restaurantActions from '../actions/restaurantActions';
import { getQueryString } from '../../utilities/utilities';
import { axiosWithoutRetry } from '../../utilities/apiHelper';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === restaurantActions.GET_RESTAURANTS_RESPONSE) {
        const restaurantDeepLinkId = Number(getQueryString('restaurantid'));
        if (restaurantDeepLinkId) {
            dispatch(restaurantActions.getRestaurantExistance());
            try {
                await axiosWithoutRetry().get(`${Urls.GET_RESTAURANT_BY_ID}${restaurantDeepLinkId}`);
                dispatch(restaurantActions.selectRestaurant(restaurantDeepLinkId));
            } catch (e) {
                console.log(`Deeplink restaurantId "${restaurantDeepLinkId}" not found`);
            }
            dispatch(restaurantActions.getRestaurantExistanceResponse());
        }
    }
};
