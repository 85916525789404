export const CREATE_BOOKING = 'MWEB/CREATE_BOOKING';
export const CREATE_BOOKING_RESPONSE = 'MWEB/CREATE_BOOKING_RESPONSE';
export const CREATE_BOOKING_ERROR = 'MWEB/CREATE_BOOKING_ERROR';

export const SUBMIT_BOOKING_FORM = 'MWEB/SUBMIT_BOOKING_FORM';

export const UPDATE_BOOKING = 'MWEB/UPDATE_BOOKING';
export const UPDATE_BOOKING_RESPONSE = 'MWEB/UPDATE_BOOKING_RESPONSE';
export const UPDATE_BOOKING_ERROR = 'MWEB/UPDATE_BOOKING_ERROR';

export const CANCEL_BOOKING = 'MWEB/CANCEL_BOOKING';
export const CANCEL_BOOKING_RESPONSE = 'MWEB/CANCEL_BOOKING_RESPONSE';
export const CANCEL_BOOKING_ERROR = 'MWEB/CANCEL_BOOKING_ERROR';
export const RESET_CANCEL_BOOKING_STATE = 'MWEB/RESET_CANCEL_BOOKING_STATE';
export const GET_BOOKINGS = 'MWEB/GET_BOOKINGS';
export const GET_BOOKINGS_RESPONSE = 'MWEB/GET_BOOKINGS_RESPONSE';
export const GET_BOOKINGS_ERROR = 'MWEB/GET_BOOKINGS_ERROR';
export const SHOW_PRE_RESERVATION_TEXT = 'MWEB/SHOW_PRE_RESERVATION_TEXT';
export const HIDE_PRE_RESERVATION_TEXT = 'MWEB/HIDE_PRE_RESERVATION_TEXT';
export const PRE_RESERVATION_CONFIRMED = 'MWEB/PRE_RESERVATION_CONFIRMED';
export const PRE_RESERVATION_DECLINED = 'MWEB/PRE_RESERVATION_DECLINED';
export const GET_EXTRA_RESERVATION_FIELDS = 'MWEB/GET_EXTRA_RESERVATION_FIELDS';
export const GET_EXTRA_RESERVATION_FIELDS_RESPONSE = 'MWEB/GET_EXTRA_RESERVATION_FIELDS_RESPONSE';
export const GET_EXTRA_RESERVATION_FIELDS_ERROR = 'MWEB/GET_EXTRA_RESERVATION_FIELDS_ERROR';

export const createBooking = booking => ({ type: CREATE_BOOKING, booking });

export const createBookingResponse = (response, booking) => ({ type: CREATE_BOOKING_RESPONSE, response, booking });

export const createBookingError = error => ({ type: CREATE_BOOKING_ERROR, error });

export const submitBookingForm = booking => ({ type: SUBMIT_BOOKING_FORM, booking });

export const updateBooking = booking => ({ type: UPDATE_BOOKING, booking });

export const updateBookingResponse = (response, booking) => ({ type: UPDATE_BOOKING_RESPONSE, response, booking });

export const updateBookingError = error => ({ type: UPDATE_BOOKING_ERROR, error });

export const cancelBooking = booking => ({ type: CANCEL_BOOKING, booking });

export const cancelBookingResponse = response => ({ type: CANCEL_BOOKING_RESPONSE, response });

export const cancelBookingError = error => ({ type: CANCEL_BOOKING_ERROR, error });

export const resetRemotingCancelState = () => ({ type: RESET_CANCEL_BOOKING_STATE });

export const getBookings = () => ({ type: GET_BOOKINGS });

export const getBookingsResponse = response => ({ type: GET_BOOKINGS_RESPONSE, response });

export const getBookingsError = error => ({ type: GET_BOOKINGS_ERROR, error });

export const showPreReservationText = () => ({ type: SHOW_PRE_RESERVATION_TEXT });

export const hidePreReservationText = () => ({ type: HIDE_PRE_RESERVATION_TEXT });

export const preReservationConfirmed = () => ({ type: PRE_RESERVATION_CONFIRMED });

export const preReservationDeclined = () => ({ type: PRE_RESERVATION_DECLINED });

export const getExtraReservationFields = restaurantId => ({ type: GET_EXTRA_RESERVATION_FIELDS, restaurantId });

export const getExtraReservationFieldsResponse = response => ({
    type: GET_EXTRA_RESERVATION_FIELDS_RESPONSE,
    response,
});

export const getExtraReservationFieldsError = error => ({ type: GET_EXTRA_RESERVATION_FIELDS_ERROR, error });
