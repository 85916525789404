import Urls from '../../constants/urls';
import * as bookingActions from '../actions/bookingActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === bookingActions.GET_EXTRA_RESERVATION_FIELDS) {
        const url = `${Urls.GET_EXTRA_FIELDS}${action.restaurantId}`;
        try {
            const response = await axios.get(url);
            return dispatch(bookingActions.getExtraReservationFieldsResponse(response.data));
        } catch (error) {
            Logger.error(url, error.response);
            return dispatch(bookingActions.getExtraReservationFieldsError(error.response));
        }
    }
};
