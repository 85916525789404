import Urls from '../../constants/urls';
import * as availabilityActions from '../actions/availabilityActions';
import * as utilities from '../../utilities/utilities';
import axios from 'axios';
import Logger from '../../utilities/logger';

const REGISTERED_ACTION_TYPES = [availabilityActions.GET_AVAILABILITIES];

let currentDate = null;

let requestAvailabilityQueue = [];
let requestAvailabilityTimeout = null;

const MAX_AVAILABILITY_QUERY = 4;

export default store => next => action => {
    let dispatch = store.dispatch;

    function getAvailabilities(action) {
        let date = utilities.dateToApiString(action.date);
        let restaurantIds = action.restaurantIds;
        if (date !== currentDate) {
            if (requestAvailabilityTimeout) clearTimeout(requestAvailabilityTimeout);
            requestAvailabilityQueue = [];
        }
        currentDate = date;
        requestAvailabilityQueue = requestAvailabilityQueue.concat(restaurantIds);
        if (requestAvailabilityTimeout) clearTimeout(requestAvailabilityTimeout);
        requestAvailabilityTimeout = setTimeout(() => getAvailabilities2(), 1000);
    }

    function getAvailabilities2() {
        if (requestAvailabilityQueue.length > MAX_AVAILABILITY_QUERY) {
            let ids = requestAvailabilityQueue.slice(0, MAX_AVAILABILITY_QUERY);
            requestAvailabilityQueue = requestAvailabilityQueue.slice(
                MAX_AVAILABILITY_QUERY,
                requestAvailabilityQueue.length
            );
            getAvailabilities3(ids, currentDate);
            if (requestAvailabilityTimeout) clearTimeout(requestAvailabilityTimeout);
            requestAvailabilityTimeout = setTimeout(() => getAvailabilities2(), 1000);
        } else {
            getAvailabilities3(requestAvailabilityQueue, currentDate);
            requestAvailabilityQueue = [];
        }
    }

    function getAvailabilities3(restaurantIds, date) {
        const request = { restaurantIds, date };
        if (date === currentDate) {
            axios
                .post(Urls.GET_AVAILBILITY, request)
                .then(response =>
                    dispatch(availabilityActions.getAvailabilitiesResponse(response.data, restaurantIds, date))
                )
                .catch(error => {
                    Logger.error(Urls.GET_AVAILBILITY, error.response);
                    dispatch(availabilityActions.getAvailabilitiesError(error.response, restaurantIds, date));
                });
        }
    }

    function executeAction(action) {
        switch (action.type) {
            case availabilityActions.GET_AVAILABILITIES:
                next(action);
                getAvailabilities(action);
                break;

            default:
                next(action);
        }
    }

    if (REGISTERED_ACTION_TYPES.indexOf(action.type) !== -1) {
        executeAction(action);
    } else {
        return next(action);
    }
};
