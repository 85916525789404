import * as apiHelper from './apiHelper';
import * as storage from './storage';

let connectionHub;

let stateEnum = {
    connecting: 0,
    connected: 1,
    reconnecting: 2,
    disconnected: 4,
};

let restartCallback = () => {};
let successCallback = () => {};
let reconnectedCallback = () => {};
let errorCallback = () => {};

const defaultMaxRetry = 10;
const defaultDelayMs = 100;

function isOnline() {
    return window.navigator.onLine;
}

let randomInitialDelay = Math.floor(Math.random() * 100);

function restartConnection(max, incrementDelay) {
    let delay = randomInitialDelay + incrementDelay;

    console.log('restart - max', max, 'next delay', delay);

    if (!isOnline()) {
        console.log('restart - on hold as offline');
        setTimeout(() => {
            restartConnection(defaultMaxRetry, defaultDelayMs);
        }, 1000);
        return;
    }

    if (max > 0) {
        setTimeout(() => {
            try {
                let currentState = connectionHub.connection.connectionState;
                if (currentState === stateEnum.connected) {
                    reconnectedCallback();
                }

                connectionHub
                    .start()
                    .then(() => {
                        reconnectedCallback();
                    })
                    .catch(err => {
                        console.log('restart connection error - ' + err.toString());
                        restartConnection(--max, incrementDelay * 2);
                    });
            } catch (err) {
                console.log('failed restart catch all - ' + err.toString());
                restartConnection(--max, incrementDelay * 2);
            }
        }, delay);
    } else {
        let error = 'failed restart - retries exceeded';
        console.log(error);
        errorCallback(error);
    }
}

const signalRHelper = {
    initialiseConnection: (restart, success, reconnected, error) => {
        console.log('init connection', apiHelper.getWebSocketApiUrl());

        restartCallback = restart;
        successCallback = success;
        reconnectedCallback = reconnected;
        errorCallback = error;

        if (!connectionHub) {
            connectionHub = new window.signalR.HubConnection(apiHelper.getWebSocketApiUrl(), {
                accessTokenFactory: () => storage.getToken(),
            });

            connectionHub.logging = true;
        }

        connectionHub.onclose(() => {
            restartCallback();
        });

        const connectionStatus = connectionHub.connection.connectionState;
        if (connectionStatus === stateEnum.reconnecting || connectionStatus === stateEnum.disconnected) {
            connectionHub
                .start()
                .then(function() {
                    successCallback();
                })
                .catch(err => {
                    console.log('start connection error - ' + err.toString());
                    restartConnection(defaultMaxRetry, defaultDelayMs);
                });
        }
        return connectionHub;
    },

    restartConnection: () => {
        const connectionStatus = connectionHub.connection.connectionState;
        if (connectionStatus === stateEnum.connected || connectionStatus === stateEnum.connecting) {
            // stop auto triggers restart
            connectionHub.stop();
        } else {
            restartConnection(defaultMaxRetry, defaultDelayMs);
        }
    },

    getConnectionHub: () => connectionHub,
};

export default signalRHelper;
