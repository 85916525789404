export const SELECT_RESTAURANT_GROUP = 'MWEB/SELECT_RESTAURANT_GROUP';
export const SELECT_RESTAURANT_ON_MAP = 'MWEB/SELECT_RESTAURANT_ON_MAP';
export const SELECT_LIST_PAGE = 'MWEB/SELECT_LIST_PAGE';
export const SELECT_MAP_PAGE = 'MWEB/SELECT_MAP_PAGE';
export const SELECT_SLOT = 'MWEB/SELECT_SLOT';
export const SELECT_PROFILE = 'MWEB/SELECT_PROFILE';
export const SELECT_BOOKINGS = 'MWEB/SELECT_BOOKINGS';
export const SELECT_SEARCH = 'MWEB/SELECT_SEARCH';
export const BOOKING_CONFIRMED = 'MWEB/BOOKING_CONFIRMED';
export const SELECT_RESTAURANT = 'MWEB/SELECT_RESTAURANT_UIA';
export const SHOW_SELECT_LOCATION = 'MWEB/SHOW_SELECT_LOCATION';

export const selectRestaurantGroup = restaurantGroup => ({
    type: SELECT_RESTAURANT_GROUP,
    restaurantGroup,
});

export const selectRestaurantOnMap = restaurantId => ({
    type: SELECT_RESTAURANT_ON_MAP,
    restaurantId,
});

export const selectListPage = () => ({
    type: SELECT_LIST_PAGE,
});

export const selectMapPage = () => ({
    type: SELECT_MAP_PAGE,
});

export const selectSlot = (slot, restaurant) => ({
    type: SELECT_SLOT,
    slot,
    restaurant,
});

export const selectProfile = () => ({
    type: SELECT_PROFILE,
});

export const selectBookings = () => ({
    type: SELECT_BOOKINGS,
});

export const selectSearch = () => ({
    type: SELECT_SEARCH,
});

export const bookingConfirmed = () => ({
    type: BOOKING_CONFIRMED,
});

export const selectRestaurant = restaurantId => ({
    type: SELECT_RESTAURANT,
    restaurantId,
});

export const showSelectLocation = () => ({
    type: SHOW_SELECT_LOCATION,
});
