import Urls from '../../constants/urls';
import * as guestActions from '../actions/guestActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === guestActions.GET_GUEST_DETAILS) {
        try {
            const response = await axios.get(Urls.GET_GUEST_PROFILE);
            return dispatch(guestActions.getGuestDetailsResponse(response.data));
        } catch (error) {
            Logger.error(Urls.GET_GUEST_PROFILE, error.response);
            return dispatch(guestActions.getGuestDetailsError(error.response));
        }
    }
};
