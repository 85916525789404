import { restaurantActions } from '../../actions';
import { getConfig } from '../../../../../config';
import axios from 'axios';
import serviceHeaders from '../../../constants/serviceHeaders';

export default ({ dispatch }) => next => action => {
    next(action);
    if (restaurantActions.GET_RESTAURANTS === action.type) {
        return axios
            .get(`${getConfig().webGatewayApiUrl}restaurant/api/v1/store/getStoresNearUser/${action.search}`, {
                headers: serviceHeaders,
            })
            .then(response => dispatch(restaurantActions.getRestaurantsResponse(response.data)))
            .catch(error => dispatch(restaurantActions.getRestaurantsError(error.data)));
    }
};
