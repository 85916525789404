import { getConfig } from '../../../config';
import * as storage from '../utilities/storage';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ClientId: getConfig().securityGatewayClientId,
};
if (storage.hasToken) {
    headers['Authorization'] = `Bearer ${storage.getToken()}`;
}
export default headers;
