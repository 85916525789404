import User from '../../data/user';
import { systemActions } from '../actions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import DiningSession from '../../data/diningSession';
import Store from '../../data/store';
import * as storage from '../../utilities/storage';

const user = storage.getUser();

const initialState = {
    signedIn: Boolean(user),
    unauthorizedError: false,
    signedInNextAction: null,
    user: user ? User.fromJson(user) : null,
    selectedRestaurant: null,
    diningSession: null,

    socketStatus: {
        online: false,
        message: null,
    },
    remotingSignIn: {
        busy: false,
        message: null,
    },
    remotingSignUp: {
        busy: false,
        message: null,
    },
    remotingDiningSession: {
        busy: false,
        message: null,
    },
};

function syncedDiningSession(state, action) {
    let diningSession = DiningSession.fromJson(action.diningSession);
    return Object.assign({}, state, {
        diningSession,
    });
}

function signedInNextAction(state, action) {
    return Object.assign({}, state, {
        signedInNextAction: action.nextAction,
    });
}

function signout(state) {
    return Object.assign({}, state, {
        signedIn: false,
        user: null,
        selectedRestaurant: null,
    });
}

function signin(state) {
    return Object.assign({}, state, {
        signedIn: false,
        user: null,
        remotingSignIn: remotingUtilities.getBusyState(),
    });
}

function signup(state) {
    return Object.assign({}, state, {
        signedIn: false,
        user: null,
        remotingSignUp: remotingUtilities.getBusyState(),
    });
}

function signinResponse(state, action) {
    return Object.assign({}, state, {
        signedIn: true,
        user: User.fromJson(action.response),
        remotingSignIn: remotingUtilities.getRemotingState(),
    });
}

function signupResponse(state, action) {
    return Object.assign({}, state, {
        remotingSignUp: remotingUtilities.getRemotingState(),
    });
}

function signinError(state, action) {
    return Object.assign({}, state, {
        remotingSignIn: remotingUtilities.getErrorState(action.error),
    });
}

function signupError(state, action) {
    return Object.assign({}, state, {
        remotingSignUp: remotingUtilities.getErrorState(action.response.message),
    });
}

function getDiningSession(state) {
    return Object.assign({}, state, {
        diningSession: null,
        remotingDiningSession: remotingUtilities.getBusyState(),
    });
}

function getDiningSessionResponse(state, action) {
    return Object.assign({}, state, {
        diningSession: DiningSession.fromJson(action.response),
        remotingDiningSession: remotingUtilities.getRemotingState(),
    });
}

function getDiningSessionError(state, action) {
    return Object.assign({}, state, {
        remotingDiningSession: remotingUtilities.getErrorState(action.error.message),
    });
}

function switchPage(state, action) {
    return Object.assign({}, state, {
        page: action.page,
    });
}

function storeSelected(state, action) {
    return { ...state, selectedRestaurant: action.restaurantId || null };
}

function loadStore(state, action) {
    let store = null;
    try {
        store = Store.fromJson(action.store);
    } catch (error) {
        console.warn(error);
    }
    return Object.assign({}, state, {
        store,
    });
}

function setDeviceGeoPosition(state, action) {
    return Object.assign({}, state, {
        deviceGeoPosition: action.geoPosition,
    });
}

function setSocketStatus(state, online, message = null) {
    return Object.assign({}, state, {
        socketStatus: { online, message },
    });
}

function networkStatus(state, online) {
    return Object.assign({}, state, { online });
}

export default function systemReducer(state = initialState, action = {}) {
    switch (action.type) {
        case systemActions.RESET_REMOTING_STATE:
            return Object.assign({}, state, {
                remotingSignIn: remotingUtilities.getRemotingState(),
                remotingSignUp: remotingUtilities.getRemotingState(),
            });

        case systemActions.SIGNIN:
            return signin(state, action);
        case systemActions.SIGNIN_RESPONSE:
            return signinResponse(state, action);
        case systemActions.SIGNIN_ERROR:
            return signinError(state, action);

        case systemActions.SIGNUP:
            return signup(state, action);
        case systemActions.SIGNUP_RESPONSE:
            return signupResponse(state, action);
        case systemActions.SIGNUP_ERROR:
            return signupError(state, action);

        case systemActions.GET_DINING_SESSION:
            return getDiningSession(state, action);
        case systemActions.GET_DINING_SESSION_RESPONSE:
            return getDiningSessionResponse(state, action);
        case systemActions.GET_DINING_SESSION_ERROR:
            return getDiningSessionError(state, action);

        case systemActions.CLEAR_DINING_SESSION:
            return Object.assign({}, state, {
                diningSession: null,
            });

        case systemActions.FORCE_SIGN_OUT:
            return signout(state, action);

        case systemActions.SWITCH_PAGE:
            return switchPage(state, action);
        case systemActions.STORE_SELECTED:
            return storeSelected(state, action);
        case systemActions.LOAD_STORE:
            return loadStore(state, action);

        case systemActions.SIGNED_IN_NEXT_ACTION:
            return signedInNextAction(state, action);
        case systemActions.SET_DEVICE_GEO_POSITION:
            return setDeviceGeoPosition(state, action);
        case systemActions.SYNCED_DINING_SESSION:
            return syncedDiningSession(state, action);

        case systemActions.START_SOCKET:
            return setSocketStatus(state, false);

        case systemActions.SOCKET_STARTED:
            return setSocketStatus(state, true);

        case systemActions.RECONNECTED_SOCKET:
            return setSocketStatus(state, true);

        case systemActions.RESTART_SOCKET:
            return setSocketStatus(state, false);

        case systemActions.SOCKET_ERROR:
            return setSocketStatus(state, false, action.error);

        case systemActions.NETWORK_STATUS:
            return networkStatus(state, action.online);

        case systemActions.UNAUTHORIZED_ERROR:
            return {
                ...state,
                signedIn: false,
                unauthorizedError: true,
            };

        case systemActions.RESET_UNAUTHORIZED_ERROR:
            return {
                ...state,
                unauthorizedError: false,
            };

        default:
            return state;
    }
}
