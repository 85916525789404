import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './redux/reducers';
import brocAppMiddlewares from './redux/middleware';
import reduxWhenMiddleware from 'redux-when';

import { middlewares as mWEBMiddleware } from '../../mWeb/js/redux/store';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

export default function configureStore(initialState) {
    return createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(reduxWhenMiddleware, ...brocAppMiddlewares, ...mWEBMiddleware, routerMiddleware(history))
        )
    );
}
