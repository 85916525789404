import Restaurant from './restaurant';
import * as utilities from '../utilities/utilities';
import _get from 'lodash/get';

export default class ConfirmedBooking {
    // aka reservation
    constructor(booking) {
        this.reservationId = _get(booking, 'reservationId', -1);
        this.covers = _get(booking, 'covers', -1);
        this.date = _get(booking, 'date', null);
        this.cuisineTypeId = _get(booking, 'cuisineTypeId', -1);
        this.startInMinutesPastMidnight = _get(booking, 'startInMinutesPastMidnight', -1);
        this.notes = _get(booking, 'specialRequests', '');
        this.isCancelled = _get(booking, 'isCancelled', false);
        this.reservationState = _get(booking, 'reservationState', -1);
        const restaurantDetail = _get(booking, 'restaurantDetail', {});
        this.restaurantId = restaurantDetail.restaurantId || -1;
        this.restaurant = restaurantDetail
            ? Restaurant.fromJson({
                  ...restaurantDetail,
                  restaurantName: restaurantDetail.name,
              })
            : null;
        this.guest = _get(booking, 'guest', null);
    }

    id() {
        return this.reservationId;
    }

    setBooking(booking) {
        try {
            this.restaurant = booking.restaurant.clone();
            this.guest = booking.guest.clone();
        } catch (error) {
            console.warn(error);
        }
    }

    getGuestName() {
        if (this.guest) return this.guest.firstName + ' ' + this.guest.lastName;
        return '';
    }

    getRestaurantDetails() {
        if (this.restaurant) {
            return `${this.restaurant.getName()} ${this.restaurant.city ? ',' + this.restaurant.city : ''}`;
        }
        return '';
    }

    getDateString() {
        return utilities.dateToTextForBookingEntry(utilities.apiStringToDate(this.date));
    }

    getTimeString() {
        return utilities.minutesPastMidnightToTimeString(this.startInMinutesPastMidnight);
    }

    getRestaurantName() {
        if (this.restaurant) return this.restaurant.name;
        return this.restaurantId;
    }

    getRestaurant() {
        if (this.restaurant) return this.restaurant.clone();
        return Restaurant.fromJson({ restaurantId: this.restaurantId });
    }

    clone() {
        return ConfirmedBooking.fromJson(this);
    }

    toSaveObject() {
        return {
            reservationId: this.reservationId,
            covers: this.covers,
            date: this.date,
            startInMinutesPastMidnight: this.startInMinutesPastMidnight,
            notes: this.notes,
        };
    }

    toCancelRequest() {
        return {
            reservationId: this.reservationId,
        };
    }

    toString() {
        return JSON.stringify(this.toSaveObject());
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new ConfirmedBooking(json);
    }

    static sortByDate(b1, b2) {
        if (b1.date === b2.date) {
            if (b1.startInMinutesPastMidnight === b2.startInMinutesPastMidnight) return b1.id() > b2.id() ? -1 : 1;
            return b1.startInMinutesPastMidnight > b2.startInMinutesPastMidnight ? -1 : 1;
        }
        return b1.date > b2.date ? -1 : 1;
    }
}
