import Urls from '../constants/urls';
import * as Storage from './storage';
import axios from 'axios';
import { getQueryString } from './utilities';
import { USER_ID_QUERY_STRING_KEY } from '../constants/constants';

const MAX_RETRIES = 3;
const authToken = () => 'Bearer ' + Storage.getAuthToken();
export const getUserId = () => getQueryString(USER_ID_QUERY_STRING_KEY) || getQueryString('altid'); // Or 'altid' fallback left in during transition to new key

export const refreshToken = () => {
    const altIdValue = getUserId();
    return axios
        .get(`${Urls.GET_TOKEN}?altid=${altIdValue}`)
        .then(response => {
            Storage.setAuthToken(response.data.token);
            return response.data.token;
        })
        .catch(error => console.log('get token error', error));
};

export const axiosWithoutRetry = () => axios.create({ headers: { Authorization: authToken } });

export function initAxios() {
    const rax = require('retry-axios');
    axios.defaults.timeout = 10000;
    axios.interceptors.request.use(config => {
        config.headers.Authorization = 'Bearer ' + Storage.getAuthToken();
        config.headers['Accept-Language'] = Storage.getLocale();
        return config;
    });
    rax.attach();
    axios.defaults.raxConfig = {
        statusCodesToRetry: [[400, 599]],
        retryDelay: 500,
        httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
        shouldRetry: err => {
            const cfg = rax.getConfig(err);
            console.log('RETRY - ' + cfg.currentRetryAttempt);
            if (cfg.currentRetryAttempt >= MAX_RETRIES) return false;

            if (err.response.config.method === 'post') {
                // Only allow 401 posts to be retried
                return err.response.status === 401;
            }
            return true;
        },
        onRetryAttempt: ({ response }) => {
            if (response.status === 401) {
                return refreshToken();
            }
        },
    };
}
