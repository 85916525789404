import Location from '../../data/location';
import Place from '../../data/place';
import * as locationActions from '../actions/locationActions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import * as storage from '../../utilities/storage';

const initialState = {
    deviceLocation: null,
    deviceLocationDenied: false,
    selectedLocation: null,

    placeQueryString: '',
    placeQueryResult: [],
    placeQuery: { busy: false, error: null },
};

function setDeviceLocation(state, action) {
    let deviceLocation = state.deviceLocation;
    let selectedLocation = state.selectedLocation;

    try {
        deviceLocation = Location.fromDeviceLocation(action.location);
        if (deviceLocation && !selectedLocation) selectedLocation = deviceLocation.clone();
    } catch (error) {
        console.warn(error);
    }

    return { ...state, deviceLocation, selectedLocation, deviceLocationDenied: false };
}

function setDeviceLocationAsSearchLocation(state) {
    let deviceLocation = state.deviceLocation;
    let selectedLocation = state.selectedLocation;

    try {
        if (deviceLocation) selectedLocation = deviceLocation.clone();
    } catch (error) {
        console.warn(error);
    }

    return { ...state, selectedLocation };
}

function setSelectedLocation(state, action) {
    let selectedLocation = state.selectedLocation;
    try {
        selectedLocation = { ...action.location };
        storage.addLocation(selectedLocation);
    } catch (error) {
        console.warn(error);
    }
    return Object.assign({}, state, { selectedLocation });
}

function clear(state, action) {
    return Object.assign({}, state, {
        placeQueryString: '',
        placeQueryResult: [],
        placeQuery: remotingUtilities.getRemotingState(),
    });
}

function queryForPlace(state, action) {
    return Object.assign({}, state, {
        placeQueryString: action.queryString,
        placeQueryResult: [],
        placeQuery: remotingUtilities.getBusyState(),
    });
}

function queryForPlaceResponse(state, action) {
    const places = action.response || [];
    const placeQueryResult = places.map(p => Place.fromJson(p));

    return Object.assign({}, state, {
        placeQueryResult,
        placeQuery: remotingUtilities.getRemotingState(),
    });
}

function queryForPlaceError(state, action) {
    return Object.assign({}, state, { placeQuery: remotingUtilities.getErrorState(action.error) });
}

export default function locationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case locationActions.SET_DEVICE_LOCATION_DENIED:
            return Object.assign({}, state, { deviceLocationDenied: true });

        case locationActions.SET_DEVICE_LOCATION:
            return setDeviceLocation(state, action);
        case locationActions.SET_SELECTED_LOCATION:
            return setSelectedLocation(state, action);
        case locationActions.USE_DEVICE_LOCATION_AS_SEARCH_LOCATION:
            return setDeviceLocationAsSearchLocation(state);

        case locationActions.CLEAR_QUERY:
            return clear(state, action);

        case locationActions.QUERY_FOR_PLACE:
            return queryForPlace(state, action);
        case locationActions.QUERY_FOR_PLACE_RESPONSE:
            return queryForPlaceResponse(state, action);
        case locationActions.QUERY_FOR_PLACE_ERROR:
            return queryForPlaceError(state, action);

        default:
            return state;
    }
}
