export default class RestaurantFilter {
    constructor(filter) {
        this.specialOffers = filter && filter.hasOwnProperty('specialOffers') ? filter.specialOffers : false;
        this.favourites = filter && filter.hasOwnProperty('favourites') ? filter.favourites : false;
    }

    filterRestaurantList(restaurantList, favourites) {
        let a = [];
        restaurantList.forEach(r => {
            let x = !this.specialOffers || (this.specialOffers && r.hasSpecialOffers());
            x = (x && !this.favourites) || (this.favourites && favourites.indexOf(r.id()) !== -1);
            if (x) a.push(r.clone());
        });
        return a;
    }

    toggleSpecialOffers() {
        this.specialOffers = !this.specialOffers;
        return this;
    }

    toggleFavourites() {
        this.favourites = !this.favourites;
        return this;
    }

    reset() {
        this.specialOffers = false;
        this.favourites = false;
        return this;
    }

    clone() {
        return RestaurantFilter.fromJson(this);
    }

    static create() {
        return RestaurantFilter.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new RestaurantFilter(json);
    }
}
