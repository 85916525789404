import _get from 'lodash/get';
import { isSameDay, getCurrentMinsPastMidnight } from '../../utilities/dateUtilities';

export const selectedRestaurantPreReservationText = state =>
    _get(state, 'mWebApp.restaurantReducer.restaurant.preReservationText');

export const restaurantSearchListEmpty = state => _get(state, 'mWebApp.restaurantReducer.restaurants', []).length === 0;

export const selectedRestaurantId = state => _get(state, 'mWebApp.restaurantReducer.restaurant.restaurantId');

export const getAvailableSlotsForRestaurant = (state, restaurantId = null, todaysDate) => {
    const availabilityMap = _get(state, 'mWebApp.availabilityReducer.availabilityMap');
    const availabilitySearchDate = _get(state, 'mWebApp.systemReducer.date');
    const { slots = [] } = (availabilityMap && availabilityMap.get(restaurantId)) || {};
    if (isSameDay(availabilitySearchDate, todaysDate)) {
        const currentMinsPastMidnight = getCurrentMinsPastMidnight(todaysDate);
        return slots.filter(s => s.startInMinutesPastMidnight > currentMinsPastMidnight);
    }
    return slots;
};

export const availabilityServiceBusy = (state, restaurantId = null) => {
    const remotingMap = _get(state, 'mWebApp.availabilityReducer.remotingMap');
    let busy = true;
    if (remotingMap.has(restaurantId)) {
        busy = remotingMap.get(restaurantId).busy;
    }
    return busy;
};
