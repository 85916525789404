import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ProximaNovaRegular, ProximaNovaSemibold } from './fonts/proxima-nova';
import { SwissBoldCondensed } from './fonts/swiss';

const primaryColor = '#1C1C1C';
const secondaryColor = '#FFFFFF';
const primaryCtaColor = '#444054';
const secondaryCtaColor = '#FFFFFF';
const accentColor = '#B84C16';
const successColor = '#6CB120';
const errorColor = '#D0021B';

const theme = createMuiTheme({
    palette: {
        primary: { main: primaryColor },
        secondary: { main: secondaryColor },
        accent: { main: accentColor },
        primaryCtaColor: { main: primaryCtaColor },
        secondaryCtaColor: { main: secondaryCtaColor },
        error: { main: successColor },
        success: { main: errorColor },
    },
    typography: {
        fontFamily: ['proxima-nova', 'Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        htmlFontSize: 16,
        body1: {
            fontSize: '16px',
        },
        h1: {
            fontSize: '24px',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [ProximaNovaRegular, ProximaNovaSemibold, SwissBoldCondensed],
            },
        },
        MuiBottomNavigationAction: {
            root: {
                color: 'rgba(255, 251, 251, 0.54)',
                '&$selected': {
                    color: secondaryColor,
                },
                padding: '6px 0px 8px',
            },
        },
        MuiBottomNavigation: {
            root: {
                width: '100%',
                position: 'fixed',
                zIndex: '9999',
                bottom: 0,
                left: 0,
                backgroundColor: primaryColor,
            },
        },
        MuiButton: {
            root: {
                borderRadius: '0',
                fontFamily: 'swiss-bold',
                fontSize: 16,
            },
            sizeLarge: {
                fontSize: 18,
            },
        },
        MuiCard: {
            root: {
                borderRadius: '0',
            },
        },
    },
});

export default responsiveFontSizes(theme);
