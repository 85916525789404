import * as geometryUtilities from '../../../common/utilities/geometryUtilities';
import BaseDataObject from './baseDataObject';
import _get from 'lodash/get';

export default class Store extends BaseDataObject {
    constructor(store) {
        super(store);

        this.brandId = store && store.hasOwnProperty('brandId') ? store.brandId : -1;

        this.name = store && store.hasOwnProperty('name') ? store.name : '';

        this.cuisineType = store && store.hasOwnProperty('cuisineType') ? store.cuisineType : '';
        this.pictureUrl = store && store.hasOwnProperty('pictureUrl') ? store.pictureUrl : '';

        const location = _get(store, 'location', {});
        this.location = {
            address: location.address || '',
            city: location.city || '',
            stateOrCounty: location.stateOrCounty || '',
            country: location.country || '',
            postCodeOrZip: location.postCodeOrZip || '',
            latitude: location.latitude || _get(location, 'geocode.latitude', -1),
            longitude: location.longitude || _get(location, 'geocode.longitude', -1),
        };

        this.telephoneNumber =
            store && store.hasOwnProperty('contactDetails') && store.contactDetails.hasOwnProperty('telephoneNumber')
                ? store.contactDetails.telephoneNumber
                : '';

        this.emailAddress =
            store && store.hasOwnProperty('contactDetails') && store.contactDetails.hasOwnProperty('emailAddress')
                ? store.contactDetails.emailAddress
                : '';

        const currency = _get(store, 'currency', {});
        this.currency = {
            baseCulture: currency.baseCulture || '',
            currencyFormat: currency.currencyFormat || '',
            currencyIsoCode: currency.currencyIsoCode || '',
            currencyName: currency.currencyName || '',
            currencySymbol: currency.currencySymbol || '',
            isCurrencySymbolAfter: currency.isCurrencySymbolAfter || false,
        };

        this.storeHours = _get(store, 'storeHours', []).map(hours => ({
            closingTime: hours.closingTime || '',
            dayOfWeek: hours.dayOfWeek || '',
            openingTime: hours.openingTime || '',
            storeOpen: hours.storeOpen || false,
        }));
    }

    getName() {
        return this.name;
    }

    getAddress() {
        try {
            return `${this.location.address}, ${this.location.city}, ${this.location.postCodeOrZip}`;
        } catch (error) {
            console.warn(error);
        }
        return '';
    }

    getLatLng() {
        return { lat: this.location.latitude, lng: this.location.longitude };
    }

    getDistance(latLng) {
        return geometryUtilities.distVincenty(latLng, this.getLatLng());
    }

    clone() {
        return Store.fromJson(this);
    }

    static create() {
        return new Store();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Store(json);
    }
}
