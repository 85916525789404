import i18next from 'i18next';
import _reduce from 'lodash/reduce';

export function firstCharUppercase(string) {
    if (string) {
        string = string.slice(0, 1).toUpperCase() + string.slice(1);
    }
    return string;
}

export function isNumeric(number) {
    return !isNaN(parseFloat(number)) && isFinite(number);
}

export function isEmptyObject(object) {
    for (let prop in object) {
        if (object.hasOwnProperty(prop)) return false;
    }
    return true;
}

export function isUnauthorizedError(error) {
    return error.indexOf('unauthorized') !== -1;
}

export function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function throttle(f, time = 1000) {
    if (_throttleTimeout[f]) {
        clearTimeout(_throttleTimeout[f]);
        _throttleTimeout[f] = null;
    }
    _throttleTimeout[f] = setTimeout(f, time);
}

let _throttleTimeout = {};

export function decodeHtml(html) {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}

// prices have two digits after comma
// rounding error occurs several more digits to the right
// so we can take the simple approach
export function removeFloatingPointRoundingError(number) {
    try {
        number = parseFloat(number);
        if (!isNaN(number)) {
            let split = ('' + number).split('.');
            if (split.length === 1) {
                return parseInt(split[0]);
            }
            if (split.length === 2) {
                let one = parseInt(split[0]);
                let two = parseInt(split[1]);
                let out = '' + one;
                if (two > 0) {
                    two = ('' + two).slice(0, 2); // we just cut off all digits after the first two
                    out = out + '.' + two;
                }
                return parseFloat(out);
            }
        }
    } catch (error) {
        console.warn(error);
    }
    return -1;
}

// ** TODO: test these once we get split check feedback
function roundUp(rnum, decimalPlaces = 2) {
    return Math.ceil(rnum * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}

export function getSplitFromTotalBill(totalAmountToPay, numberOfPeople) {
    const split = totalAmountToPay / numberOfPeople;
    return roundUp(split);
}

export function getSplitFromPercentageOfTotal(percentage, total) {
    return roundUp((Number(percentage) / 100) * Number(total));
}

export function getPercentageOfTotal(bill, total) {
    return roundUp((Number(bill) * 100) / Number(total), 0);
}

export function getSumOfSplits(splitBills) {
    return _reduce(splitBills, (acc, { price }) => acc + Number(price), 0);
}
// **

export function formatPrice(
    price,
    currencySymbol = i18next.t('currencySymbol'),
    currencySymbolPlacement = i18next.t('currencySymbolPlacement'),
    currencyDecimalDivider = i18next.t('currencyDecimalDivider')
) {
    let roundPrice = removeFloatingPointRoundingError(price);

    if (roundPrice === -1 && price !== roundPrice) {
        return '-1';
    }

    let mDivider = currencyDecimalDivider === '.' ? ',' : '.';

    try {
        let split = ('' + roundPrice).split('.');
        let one = split[0];
        let two = null;

        if (split.length === 2) {
            two = parseInt(split[1]);

            if (two > 0) {
                two = '' + two;
                if (two.length === 1) two = two + '0';
                if (two.length > 2) two = two.slice(0, 2);
            }
        }

        if (!two) {
            two = '00';
        }

        if (one.length > 3) {
            let one2 = '';
            while (one.length > 3) {
                one2 = mDivider + one.slice(one.length - 3) + one2;
                one = one.substr(0, one.length - 3);
            }
            one = one + one2;
        }

        let out = one + currencyDecimalDivider + two;

        if (currencySymbolPlacement === '0') out = currencySymbol + ' ' + out;
        if (currencySymbolPlacement === '1') out = out + ' ' + currencySymbol;

        return out;
    } catch (error) {
        console.warn(error);
    }

    return '-1';
}

export function getUtcNow() {
    var now = new Date();
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return utc;
}

export function emailRegex() {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/;
}

export function stringIsNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;

    return input.replace(/\s/g, '').length < 1;
}
