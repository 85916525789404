import * as restaurantActions from '../actions/restaurantActions';
import routes from '../../constants/routes';
import { push } from 'connected-react-router';
import _get from 'lodash/get';

export default ({ dispatch, getState }) => next => action => {
    if (action.type === restaurantActions.LOAD_RESTAURANTS) {
        const state = getState();
        let location = _get(state, 'mWebApp.locationReducer.selectedLocation');
        if (location) {
            const height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
            const pageSize = Math.ceil(height / 150) + 1;
            dispatch(restaurantActions.getRestaurants(location, 1, pageSize));
            dispatch(push(`${routes.HOME.path}${window.location.search}`));
        } else {
            // If no selected location or device location - force a location search
            dispatch(push(`${routes.SELECT_LOCATION.path}${window.location.search}`));
        }
    }
    next(action);
};
