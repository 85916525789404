export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const REMOVE_MENU_ITEM = 'REMOVE_MENU_ITEM';

export const BASKET_SYNCED = 'BASKET_SYNCED';
export const CREATE_BASKET = 'CREATE_BASKET';
export const LOAD_BASKET = 'LOAD_BASKET';
export const BASKET_ITEM_SELECTED = 'BASKET_ITEM_SELECTED';
export const UDPATE_BASKET_ITEM = 'UDPATE_BASKET_ITEM';

export const PLACE_USER_ORDER = 'PLACE_USER_ORDER';
export const PLACE_USER_ORDER_RESPONSE = 'PLACE_USER_ORDER_RESPONSE';
export const PLACE_USER_ORDER_ERROR = 'PLACE_USER_ORDER_ERROR';

export const PLACE_TABLE_ORDER = 'PLACE_TABLE_ORDER';
export const PLACE_TABLE_ORDER_RESPONSE = 'PLACE_TABLE_ORDER_RESPONSE';
export const PLACE_TABLE_ORDER_ERROR = 'PLACE_TABLE_ORDER_ERROR';

export const RESET_REMOTING_STATE = 'RESET_REMOTING_STATE_TABLE';

export const CLEAR_ORDER_MESSAGE = 'CLEAR_ORDER_MESSAGE';

export const SHOW_ORDER_FAILED = 'SHOW_ORDER_FAILED';

export const resetRemotingState = () => {
    return { type: RESET_REMOTING_STATE };
};

export const placeUserOrder = (userId, basketId) => {
    return { type: PLACE_USER_ORDER, userId, basketId };
};
export const placeUserOrderResponse = (
    basketResponse,
    acceptedOrderResponse,
    menuItemLookup,
    hostOrder,
    currentUserId,
    userId
) => {
    return {
        type: PLACE_USER_ORDER_RESPONSE,
        basketResponse,
        acceptedOrderResponse,
        menuItemLookup,
        hostOrder,
        currentUserId,
        userId,
    };
};
export const placeUserOrderError = (error, basket, menuItemLookup) => {
    return { type: PLACE_USER_ORDER_ERROR, error, basket, menuItemLookup };
};

export const placeTableOrder = basketId => {
    return { type: PLACE_TABLE_ORDER, basketId };
};
export const placeTableOrderResponse = (response, menuItemLookup) => {
    return { type: PLACE_TABLE_ORDER_RESPONSE, response, menuItemLookup };
};
export const placeTableOrderError = error => {
    return { type: PLACE_TABLE_ORDER_ERROR, error };
};

export const addMenuItem = (menuItem, optionSelections = null, quantity = 1, user) => {
    return {
        type: ADD_MENU_ITEM,
        menuItem,
        optionSelections,
        quantity,
        user,
    };
};

export const removeMenuItem = (menuItem, optionSelections = null, quantity = 1, userId) => {
    return {
        type: REMOVE_MENU_ITEM,
        menuItem,
        optionSelections,
        quantity,
        userId,
    };
};

export const basketItemSelected = basketItem => {
    return { type: BASKET_ITEM_SELECTED, basketItem };
};

export const updateBasketItem = (basketItem, optionSelections) => {
    return { type: UDPATE_BASKET_ITEM, basketItem, optionSelections };
};

export const createBasket = (tableId, broccoliId) => {
    return { type: CREATE_BASKET, broccoliId, tableId };
};

export const loadBasket = (basket, menuItemLookup) => {
    return { type: LOAD_BASKET, basket, menuItemLookup };
};

export const basketSynced = (basket, user, menuItemLookup, categoryLookup) => {
    return {
        type: BASKET_SYNCED,
        basket,
        user,
        menuItemLookup,
        categoryLookup,
    };
};

export const showOrderFailed = () => {
    return {
        type: SHOW_ORDER_FAILED,
    };
};

export const clearOrderMessage = () => {
    return {
        type: CLEAR_ORDER_MESSAGE,
    };
};
