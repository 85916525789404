import getRestaurants from './restaurants/getRestaurants';
import getNearbyRestaurants from './restaurants/getNearbyRestaurants';
import systemMiddleware from './system/systemMiddleware';
import tableMiddleware from './table/tableMiddleware';
import authenticationRedirectMiddleware from './authenticationRedirectMiddleware';

export default [
    getRestaurants,
    getNearbyRestaurants,
    systemMiddleware,
    authenticationRedirectMiddleware,
    tableMiddleware,
];
