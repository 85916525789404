export default class Place {
    constructor(place) {
        this.description = place && place.hasOwnProperty('description') ? place.description : '';
        this.place_id = place && place.hasOwnProperty('place_id') ? place.place_id : '';
    }

    clone() {
        return Place.fromJson(this);
    }

    getName() {
        if (this.description.indexOf(',') !== -1) {
            return this.description.split(',')[0];
        }
        return this.description;
    }

    getPlaceId() {
        return this.place_id;
    }

    toLocation() {}

    static create() {
        return Place.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Place(json);
    }
}
