function getValue(identifier, defaultValue = null) {
    if (localStorage) {
        try {
            let value = localStorage.getItem(identifier);
            value = tryParseJson(value) || value;
            if (defaultValue !== null) return value || defaultValue;
            return value;
        } catch (error) {
            console.warn(error);
        }
    }
}

function setValue(identifier, value) {
    if (localStorage) {
        try {
            if (value === null || value === undefined) {
                localStorage.removeItem(identifier);
                return;
            }
            if (typeof value === 'object') value = JSON.stringify(value);
            localStorage.setItem(identifier, value);
        } catch (error) {
            console.warn(error);
        }
    }
}

function tryParseJson(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
}

export const getUser = () => getValue('broc-user', null);
export const setUser = (user = null) => setValue('broc-user', user);

export const getEmail = () => getValue('broc-email', '');
export const setEmail = (email = '') => setValue('broc-email', email);

export const getBroccoliId = () => getValue('broc-id', '');
export const setBroccoliId = (id = '') => setValue('broc-id', id);

export const getToken = () => getValue('broc-token', '');
export const setToken = (token = '') => setValue('broc-token', token);
export const hasToken = () => getToken() !== '';
