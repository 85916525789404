import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ThemeInjector from './broccoli/js/components/utils/themeInjector';
import mwebRoutes from './mWeb/js/constants/routes';
import marriottTheme from './common/themes/marriottTheme';
import BusyIndicator from './common/components/busyIndicator';
import i18next from 'i18next';

const mwebRouteComponent = lazy(() => import('./mWeb/js/components/routes'));

// *** Broccoli app turned off for now ***
// import HomeView from './broccoli/js/components/home/homeView';
// import brocRoutes from './broccoli/js/constants/routes';
// const BrocMainView = lazy(() => import('./broccoli/js/components/routes'));
// <Route exact path={brocRoutes.HOME.path} component={ThemeInjector(marriottTheme)(HomeView)} />
// ***

export default ({ store, history }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Suspense fallback={<AppLoadingSpinner text={i18next.t('loadingSpinner')} />}>
                <Route path={mwebRoutes.HOME.path} component={ThemeInjector(marriottTheme)(mwebRouteComponent)} />
                {/* Redirect everything to 'marriott/table-reservation' if not on that route */}
                <Route>
                    <Redirect to={`${mwebRoutes.HOME.path}${window.location.search}`} />
                </Route>
            </Suspense>
        </ConnectedRouter>
    </Provider>
);

const AppLoadingSpinner = ThemeInjector(marriottTheme)(BusyIndicator);
