import Guest from '../../data/guest';
import * as guestActions from '../actions/guestActions';
import * as bookingActions from '../actions/bookingActions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';

const initialState = {
    guest: null,
    guestQuerySuccess: false,
    updated: false,
    remotingLoad: { busy: false, error: null },
    remotingUpdate: { busy: false, error: null },
};

function resetRemotingState(state, action) {
    return Object.assign({}, state, {
        remotingLoad: remotingUtilities.getRemotingState(),
        remotingUpdate: remotingUtilities.getRemotingState(),
        updated: false,
    });
}

function getGuestDetails(state, action) {
    return Object.assign({}, state, { remotingLoad: remotingUtilities.getBusyState() });
}

function getGuestDetailsResponse(state, action) {
    let guest = null;
    let guestQuerySuccess = false;
    try {
        guest = Guest.fromJson(action.response);
        guestQuerySuccess = true;
    } catch (error) {
        console.warn('guest query response:', action.response);
    }
    if (!guest) {
        console.log('creating new guest');
        guest = Guest.create();
        guestQuerySuccess = true;
    }
    return Object.assign({}, state, {
        guest,
        guestQuerySuccess,
        remotingLoad: remotingUtilities.getRemotingState(),
    });
}

function createBookingResponse(state, action) {
    const guest = Guest.fromJson(action.response.guest);
    return { ...state, guest };
}

function getGuestDetailsError(state, action) {
    return Object.assign({}, state, { remotingLoad: remotingUtilities.getErrorState(action.error) });
}

function updateGuestDetails(state, action) {
    return Object.assign({}, state, { remotingUpdate: remotingUtilities.getBusyState(), updated: false });
}

function updateGuestDetailsResponse(state, action) {
    let guest = state.guest;
    try {
        guest = Guest.fromJson(action.response);
    } catch (error) {
        console.warn(error);
    }
    return Object.assign({}, state, {
        guest,
        updated: true,
        remotingUpdate: remotingUtilities.getRemotingState(),
    });
}

function updateGuestDetailsError(state, action) {
    return Object.assign({}, state, { remotingUpdate: remotingUtilities.getErrorState(action.error) });
}

export default function guestReducer(state = initialState, action = {}) {
    switch (action.type) {
        case guestActions.RESET_REMOTING_STATE:
            return resetRemotingState(state, action);

        case guestActions.GET_GUEST_DETAILS:
            return getGuestDetails(state, action);
        case guestActions.GET_GUEST_DETAILS_RESPONSE:
            return getGuestDetailsResponse(state, action);
        case guestActions.GET_GUEST_DETAILS_ERROR:
            return getGuestDetailsError(state, action);

        case guestActions.UPDATE_GUEST_DETAILS:
            return updateGuestDetails(state, action);
        case guestActions.UPDATE_GUEST_DETAILS_RESPONSE:
            return updateGuestDetailsResponse(state, action);
        case guestActions.UPDATE_GUEST_DETAILS_ERROR:
            return updateGuestDetailsError(state, action);

        case bookingActions.CREATE_BOOKING_RESPONSE:
            return createBookingResponse(state, action);

        default:
            return state;
    }
}
