// a bit confusing that restaurants in oracle/symphony are called store
// reduxStore is the redux store and not the restaurant (aka store)
export function getStoreById(storeId, reduxStore) {
    try {
        let a = reduxStore.getState().storesReducer.stores.filter(s => parseInt(s.id(), 10) === parseInt(storeId));
        if (a.length === 1) return a[0];
    } catch (error) {
        console.warn(error);
    }

    return null;
}

export function getUser(reduxStore) {
    return reduxStore.getState().systemReducer.user;
}

// a bit confusing that restaurants in oracle/symphony are called store
// reduxStore is the redux store and not the restaurant (aka store)
export function getStore(reduxStore) {
    return reduxStore.getState().systemReducer.store;
}

export function getBroccoliId(reduxStore) {
    try {
        return reduxStore.getState().systemReducer.diningSession.diningIdentifier.broccoliId;
    } catch (error) {
        return null;
    }
}

export function isTableOwner(reduxStore) {
    try {
        return reduxStore.getState().tableReducer.table.ownerId === reduxStore.getState().systemReducer.user.id();
    } catch (error) {
        console.warn(error);
    }
    return false;
}

export function getTable(reduxStore) {
    try {
        return reduxStore.getState().tableReducer.table;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getBasket(reduxStore) {
    try {
        return reduxStore.getState().basketReducer.basket;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getMenuItemLookup(reduxStore) {
    try {
        return reduxStore.getState().menuReducer.menuItemLookup;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getCategoryLookup(reduxStore) {
    try {
        return reduxStore.getState().menuReducer.categoryLookup;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getBasketItems(reduxStore) {
    try {
        return reduxStore.getState().basketReducer.basket.toSaveObject().items;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getJoinTableBusy(reduxStore) {
    try {
        return reduxStore.getState().tableReducer.remotingJoinTable.busy;
    } catch (error) {
        console.warn(error);
    }
    return false;
}

export function getMenu(reduxStore) {
    try {
        return reduxStore.getState().menuReducer.menu;
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function getPage(reduxStore) {
    try {
        return reduxStore.getState().systemReducer.page;
    } catch (error) {
        console.warn(error);
    }
    return null;
}
