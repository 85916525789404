import { tableActions, systemActions } from '../actions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import { combineReducers } from 'redux';

const initialTableState = null;

export const table = (state = initialTableState, action = {}) => {
    switch (action.type) {
        case tableActions.SYNCED_TABLE:
        case tableActions.LOAD_TABLE:
        case tableActions.JOIN_TABLE_RESPONSE:
        case tableActions.START_TABLE_RESPONSE:
            return action.table;
        case tableActions.JOIN_TABLE:
        case tableActions.START_TABLE:
        case tableActions.END_TABLE_RESPONSE:
            return initialTableState;
        default:
            return state;
    }
};

const initialRemotingState = remotingUtilities.getRemotingState();

export const remotingStartTable = (state = initialRemotingState, action = {}) => {
    switch (action.type) {
        case tableActions.RESET_REMOTING_STATE:
        case systemActions.RESET_REMOTING_STATE:
        case tableActions.START_TABLE_RESPONSE:
            return initialRemotingState;
        case tableActions.START_TABLE:
            return action.userId === -1 ? initialRemotingState : remotingUtilities.getBusyState();
        case tableActions.START_TABLE_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const remotingJoinTable = (state = initialRemotingState, action = {}) => {
    switch (action.type) {
        case tableActions.RESET_REMOTING_STATE:
        case tableActions.JOIN_TABLE_RESPONSE:
        case systemActions.RESET_REMOTING_STATE:
            return initialRemotingState;
        case tableActions.JOIN_TABLE:
            return remotingUtilities.getBusyState();
        case tableActions.JOIN_TABLE_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const remotingEndTable = (state = initialRemotingState, action = {}) => {
    switch (action.type) {
        case tableActions.RESET_REMOTING_STATE:
        case systemActions.RESET_REMOTING_STATE:
        case tableActions.END_TABLE_RESPONSE:
            return initialRemotingState;
        case tableActions.END_TABLE_ERROR:
            return remotingUtilities.getErrorState(action.error);
        case tableActions.END_TABLE:
            return action.userId === -1 ? initialRemotingState : remotingUtilities.getBusyState();
        default:
            return state;
    }
};

export const lastGuestJoinedId = (state = null, action = {}) => {
    if (action.type === tableActions.JOIN_TABLE_RESPONSE) {
        return action.userId;
    }
    return state;
};

export default combineReducers({
    table,
    remotingStartTable,
    remotingJoinTable,
    remotingEndTable,
    lastGuestJoinedId,
});
