import i18next from 'i18next';
import qs from 'query-string';

export const getQueryString = key => {
    const queryObject = qs.parse(window.location.search) || {};
    return queryObject[key];
};

export function dateToApiString(date = new Date()) {
    if (date) {
        let m = date.getMonth() + 1;
        if (('' + m).length === 1) m = '0' + m;
        let d = date.getDate();
        if (('' + d).length === 1) d = '0' + d;
        return date.getFullYear() + '-' + m + '-' + d;
    }
    return null;
}

export function apiStringToDate(string) {
    try {
        return new Date(string);
    } catch (error) {
        console.warn(error);
    }
    return null;
}

export function minutesPastMidnightToTimeString(minutesPastMidnight) {
    if (isNumeric(minutesPastMidnight)) {
        minutesPastMidnight = parseInt(minutesPastMidnight, 10);
        if (minutesPastMidnight === 0) return '00:00';
        if (minutesPastMidnight < 0) {
            minutesPastMidnight = 1440 + minutesPastMidnight;
        }
        let h = Math.floor(minutesPastMidnight / 60);
        if (h >= 24) h -= 24;
        let m = minutesPastMidnight % 60;
        return (('' + h).length === 1 ? '0' + h : '' + h) + ':' + (('' + m).length === 1 ? '0' + m : '' + m);
    }
    return '--:--';
}

export function isNumeric(number) {
    return !isNaN(parseFloat(number)) && isFinite(number);
}

export function throttle(f, time = 1000) {
    if (_throttleTimeout[f]) {
        clearTimeout(_throttleTimeout[f]);
        _throttleTimeout[f] = null;
    }
    _throttleTimeout[f] = setTimeout(f, time);
}

let _throttleTimeout = {};

export function formatReservationDetails(covers, time, date, locale, locationName = '') {
    let a = {};
    a.numberOfGuests = covers; // `${i18next.t('tableFor')} ${covers}`;
    a.date = dateToText(date, time, locale);
    a.time = `${i18next.t('at')} ${minutesPastMidnightToTimeString(time)}`;
    if (locationName !== '') {
        a.location = locationName;
    }
    return a;
}

export function dateToText(date, minutesPastMidnight = -1, locale = 'en-GB') {
    let dateString = '';
    if (date) dateString = date.toLocaleDateString(locale);
    let isToday = dateString === new Date().toLocaleDateString(locale);
    let isTonight = isToday && minutesPastMidnight > 1020;

    let d = new Date();
    d.setDate(d.getDate() + 1);
    let isTomorrow = dateString === d.toLocaleDateString(locale);

    if (isTonight) return i18next.t('tonight');
    if (isToday) return i18next.t('today');
    if (isTomorrow) return i18next.t('tomorrow');

    return (
        i18next.t('weekDaysAbbr.' + date.getDay()) +
        ' ' +
        date.getDate() +
        ' ' +
        i18next.t('monthsAbbr.' + (date.getMonth() + 1))
    );
}

export function dateToTextForBookingEntry(date) {
    try {
        let s = i18next.t('dateFormatBooking');
        s = s.replace('MM', i18next.t('months.' + (date.getMonth() + 1)));
        s = s.replace('DD', date.getDate());
        s = s.replace('YYYY', date.getFullYear());
        return s;
    } catch (error) {
        console.warn(error);
    }
    return '-';
}

export const getIndexForClosestTimeSlot = (slots = [], time) => {
    let index = slots.findIndex(s => s.startInMinutesPastMidnight === time);
    // If exact time not matched in slots
    if (index === -1 && slots.length) {
        if (time > slots[slots.length - 1].startInMinutesPastMidnight) {
            // If time is beyond the last slot set to last time
            index = slots.length - 1;
        } else if (time < slots[0].startInMinutesPastMidnight) {
            // If time is before the first slot set to first time
            index = 0;
        } else {
            // Else find the closest slot index to the time
            const closestSlot = slots.reduce((prev, curr) =>
                Math.abs(curr.startInMinutesPastMidnight - time) < Math.abs(prev.startInMinutesPastMidnight - time)
                    ? curr
                    : prev
            );
            index = slots.findIndex(s => s.startInMinutesPastMidnight === closestSlot.startInMinutesPastMidnight);
        }
    }
    return index;
};
