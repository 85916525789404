import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import restaurantReducer from './restaurantReducer';
import systemReducer from './systemReducer';
import tableReducer from './tableReducer';
import { rootReducer as mWEBReducers } from '../../../../mWeb/js/redux/store';

export default history =>
    combineReducers({
        router: connectRouter(history),
        restaurantReducer,
        systemReducer,
        tableReducer,
        mWebApp: mWEBReducers,
    });
