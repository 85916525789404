import axios from 'axios';
import Urls from '../constants/urls';

const sendMessage = (message, data, level = 'info') =>
    axios.post(Urls.LOGGER, { message, data: data, level }).catch(console.error);

export default {
    error: (message, data) => sendMessage(message, data, 'error'),
    info: (message, data) => sendMessage(message, data, 'info'),
};
