import Urls from '../../constants/urls';
import * as bookingActions from '../actions/bookingActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === bookingActions.UPDATE_BOOKING) {
        try {
            const response = await axios.put(Urls.PUT_BOOKING, action.booking.toSaveObject());
            return dispatch(bookingActions.updateBookingResponse(response.data, action.booking));
        } catch (error) {
            Logger.error(Urls.PUT_BOOKING, error.response);
            return dispatch(bookingActions.updateBookingError(error.response));
        }
    }
};
