import * as utilities from '../utilities/utilities';

export default class Slot {
    static CUISINE_TYPE_AFTERNOON_TEA = 5;

    constructor(slot) {
        this.availableCovers = slot && slot.hasOwnProperty('availableCovers') ? slot.availableCovers : -1;
        this.availablePromotionIds =
            slot && slot.hasOwnProperty('availablePromotionIds') ? slot.availablePromotionIds : [];
        this.cuisineTypeIds = slot && slot.hasOwnProperty('cuisineTypeIds') ? slot.cuisineTypeIds : [];
        this.shiftId = slot && slot.hasOwnProperty('shiftId') ? slot.shiftId : -1;
        this.startInMinutesPastMidnight =
            slot && slot.hasOwnProperty('startInMinutesPastMidnight') ? slot.startInMinutesPastMidnight : -1;

        this.availablePromotionIds.sort(function(id1, id2) {
            return id1 > id2 ? 1 : -1;
        });
    }

    getTimeString() {
        return utilities.minutesPastMidnightToTimeString(this.startInMinutesPastMidnight);
    }

    isDisabled(covers = Infinity) {
        return this.availableCovers === -1 || this.availableCovers < covers;
    }

    hasPromotions() {
        return this.availablePromotionIds.length > 0;
    }

    getNumberOfPromotions() {
        return this.availablePromotionIds.length;
    }

    getPromotionIds() {
        return this.availablePromotionIds;
    }

    hasPromotion(p) {
        try {
            return this.getPromotionIds().indexOf(p.id) !== -1;
        } catch (error) {
            console.warn(error);
        }
        return false;
    }

    toString() {
        return (
            utilities.minutesPastMidnightToTimeString(this.startInMinutesPastMidnight) +
            ' ' +
            this.availablePromotionIds.join() +
            ' ' +
            this.cuisineTypeIds.join()
        );
    }

    clone() {
        return Slot.fromJson(this);
    }

    static getPromotionIdIndex(promotionId, availability) {
        return availability.promotions.map(p => p.id).indexOf(promotionId) + 1;
    }

    static emptySlot(startInMinutesPastMidnight) {
        return Slot.fromJson({ startInMinutesPastMidnight });
    }

    static create() {
        return Slot.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Slot(json);
    }
}
