import Slot from './slot';
import Promotion from './promotion';

export default class Availability {
    constructor(availability) {
        this.restaurantId =
            availability && availability.hasOwnProperty('restaurantId') ? availability.restaurantId : -1;
        this.slots =
            availability && availability.hasOwnProperty('slots') && availability.slots
                ? availability.slots.map(s => Slot.fromJson(s))
                : [];
        this.promotions =
            availability && availability.hasOwnProperty('slots') && availability.promotions
                ? availability.promotions.map(p => Promotion.fromJson(p))
                : [];

        this.timeSlotsMap = new Map();
        this.slots.forEach(s => {
            this.timeSlotsMap.set(s.startInMinutesPastMidnight, s);
        });

        this.promotions.sort(function(p1, p22) {
            return p1.id > p22.id ? 1 : -1;
        });
    }

    id() {
        return this.restaurantId;
    }

    getSlotsTimeInterval() {
        if (this.slots.length > 1) {
            return this.slots[1].startInMinutesPastMidnight - this.slots[0].startInMinutesPastMidnight;
        }
        return 30;
    }

    getSlotForTime(time) {
        if (!this.timeSlotsMap.has(time)) this.timeSlotsMap.set(time, Slot.emptySlot(time));
        return this.timeSlotsMap.get(time);
    }

    getSlotsAroundTime(time) {
        let a = [];
        try {
            let timeInterval = this.getSlotsTimeInterval();
            a.push(this.getSlotForTime(time - timeInterval));
            a.push(this.getSlotForTime(time));
            a.push(this.getSlotForTime(time + timeInterval));
        } catch (error) {
            console.warn(error);
        }
        return a;
    }

    hasPromotions(time = -1) {
        if (time === -1) return this.promotions.length > 0;
        return this.getSlotForTime(time).hasPromotions();
    }

    getPromotionIdIndex(promotionId) {
        return this.promotions.map(p => p.id).indexOf(promotionId) + 1;
    }

    clone() {
        return Availability.fromJson(this);
    }

    static create() {
        return Availability.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Availability(json);
    }
}
