import * as locationActions from '../actions/locationActions';
import * as restaurantActions from '../actions/restaurantActions';

const REGISTERED_ACTION_TYPES = [
    locationActions.QUERY_FOR_PLACE,
    locationActions.SELECT_PLACE,
    locationActions.SET_SELECTED_LOCATION,
];

export default ({ dispatch }) => next => action => {
    const GOOGLE_RESPONSE_OK = window.google.maps.places.PlacesServiceStatus.OK;
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const geocoder = new window.google.maps.Geocoder();

    function queryForPlace({ queryString }) {
        const searchOptions = { input: queryString, types: ['(regions)'] };
        autocompleteService.getPlacePredictions(searchOptions, (data, status) => {
            if (status !== GOOGLE_RESPONSE_OK) {
                dispatch(locationActions.queryForPlaceError(new Error(status)));
            }

            dispatch(locationActions.queryForPlaceResponse(data));
        });
    }

    function selectPlace({ place: { place_id } }) {
        const searchOptions = { placeId: place_id };
        geocoder.geocode(searchOptions, (data, status) => {
            if (status !== GOOGLE_RESPONSE_OK && data.length) {
                dispatch(locationActions.queryForPlaceError(new Error(status)));
            }
            const location = data[0].geometry.location;
            const locationDetails = {
                latitude: location.lat(),
                longitude: location.lng(),
                name: data[0].formatted_address,
            };

            dispatch(locationActions.setSelectedLocation(locationDetails));
            dispatch(restaurantActions.loadRestaurants());
        });
    }

    function setSelectedLocation(action) {
        setTimeout(function() {
            dispatch(restaurantActions.loadRestaurants());
        }, 100);
    }

    function executeAction(action) {
        switch (action.type) {
            case locationActions.QUERY_FOR_PLACE:
                next(action);
                queryForPlace(action);
                break;

            case locationActions.SELECT_PLACE:
                next(action);
                selectPlace(action);
                break;
            case locationActions.SET_SELECTED_LOCATION:
                next(action);
                setSelectedLocation(action);
                break;

            default:
                next(action);
        }
    }

    if (REGISTERED_ACTION_TYPES.indexOf(action.type) !== -1) {
        executeAction(action);
    } else {
        return next(action);
    }
};
