export const SET_DATE = 'MWEB/SET_DATE';
export const SET_COVERS = 'MWEB/SET_COVERS';
export const SET_TIME = 'MWEB/SET_TIME';
export const SET_LOCALE = 'MWEB/SET_LOCALE';
export const RESET_SEARCH_DETAILS = 'MWEB/RESET_SEARCH_DETAILS';

export const setDate = date => ({ type: SET_DATE, date });

export const setCovers = covers => ({ type: SET_COVERS, covers });

export const setTime = time => ({ type: SET_TIME, time });

export const setLocale = locale => ({ type: SET_LOCALE, locale });

export const resetSearchDetails = () => ({ type: RESET_SEARCH_DETAILS });
