export const SIGNIN = 'SIGNIN';
export const SIGNIN_RESPONSE = 'SIGNIN_RESPONSE';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_RESPONSE = 'SIGNUP_RESPONSE';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const GET_DINING_SESSION = 'GET_DINING_SESSION';
export const GET_DINING_SESSION_RESPONSE = 'GET_DINING_SESSION_RESPONSE';
export const GET_DINING_SESSION_ERROR = 'GET_DINING_SESSION_ERROR';

export const CLEAR_DINING_SESSION = 'CLEAR_DINING_SESSION';

export const START_SOCKET = 'START_SOCKET';
export const SOCKET_STARTED = 'SOCKET_STARTED';
export const RECONNECTED_SOCKET = 'RECONNECTED_SOCKET';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const RESTART_SOCKET = 'RESTART_SOCKET';

export const NETWORK_STATUS = 'NETWORK_STATUS';

export const RECOVER_STATE = 'RECOVER_STATE';
export const RECOVER_STATE_ERROR = 'RECOVER_STATE_ERROR';

export const FORCE_SIGN_OUT = 'FORCE_SIGN_OUT';
export const FINISH_DINING_SESSION = 'FINISH_DINING_SESSION';
export const RESET_REMOTING_STATE = 'RESET_REMOTING_STATE';
export const STORE_SELECTED = 'STORE_SELECTED';
export const LOAD_STORE = 'LOAD_STORE';
export const SWITCH_PAGE = 'SWITCH_PAGE';
export const SIGNED_IN_NEXT_ACTION = 'SIGNED_IN_NEXT_ACTION';
export const SET_DEVICE_GEO_POSITION = 'SET_DEVICE_GEO_POSITION';
export const SYNCED_DINING_SESSION = 'SYNCED_DINING_SESSION';

export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
export const RESET_UNAUTHORIZED_ERROR = 'RESET_UNAUTHORIZED_ERROR';

export const resetRemotingState = () => {
    return {
        type: RESET_REMOTING_STATE,
    };
};

export const signup = (email = '', password = '', firstname = '', lastname = '', phone = '') => {
    return {
        type: SIGNUP,
        email,
        password,
        phone,
        firstname,
        lastname,
    };
};

export const signupError = error => {
    return {
        type: SIGNUP_ERROR,
        response: error,
    };
};

export const signupResponse = (email = '', password = '') => {
    return {
        type: SIGNUP_RESPONSE,
        email,
        password,
    };
};

export const signin = (email = '', password = '') => {
    return {
        type: SIGNIN,
        email,
        password,
    };
};
export const signinResponse = response => {
    return {
        type: SIGNIN_RESPONSE,
        response,
    };
};

export const signinError = error => {
    return {
        type: SIGNIN_ERROR,
        error,
    };
};

export const getDiningSession = (broccoliId, userId) => {
    return {
        type: GET_DINING_SESSION,
        broccoliId,
        userId,
    };
};

export const clearDiningSession = () => {
    return {
        type: CLEAR_DINING_SESSION,
    };
};
export const getDiningSessionResponse = response => {
    return {
        type: GET_DINING_SESSION_RESPONSE,
        response,
    };
};

export const getDiningSessionError = error => {
    return {
        type: GET_DINING_SESSION_ERROR,
        error,
    };
};

export const forceSignOut = () => {
    return {
        type: FORCE_SIGN_OUT,
    };
};

export const finishDiningSession = () => {
    return {
        type: FINISH_DINING_SESSION,
    };
};

export const storeSelected = restaurantId => {
    return {
        type: STORE_SELECTED,
        restaurantId,
    };
};

export const loadStore = store => {
    return {
        type: LOAD_STORE,
        store,
    };
};

export const switchPage = page => {
    return {
        type: SWITCH_PAGE,
        page,
    };
};

export const signedInNextAction = nextAction => {
    return {
        type: SIGNED_IN_NEXT_ACTION,
        nextAction,
    };
};

export const setDeviceGeoPosition = geoPosition => {
    return {
        type: SET_DEVICE_GEO_POSITION,
        geoPosition,
    };
};

export const syncedDiningSession = diningSession => {
    return {
        type: SYNCED_DINING_SESSION,
        diningSession,
    };
};

export const recoverState = (broccoliId, userId) => {
    return {
        type: RECOVER_STATE,
        broccoliId,
        userId,
    };
};

export const recoverStateError = error => {
    return {
        type: RECOVER_STATE_ERROR,
        error,
    };
};

export const startSocket = () => {
    return {
        type: START_SOCKET,
    };
};

export const socketStarted = () => {
    return {
        type: SOCKET_STARTED,
    };
};

export const reconnectedSocket = () => {
    return {
        type: RECONNECTED_SOCKET,
    };
};

export const socketError = error => {
    return {
        type: SOCKET_ERROR,
        error,
    };
};

export const restartSocket = () => {
    return {
        type: RESTART_SOCKET,
    };
};

export const networkStatus = online => {
    return { type: NETWORK_STATUS, online };
};

export const unauthorizedError = nextAction => {
    return { type: UNAUTHORIZED_ERROR, nextAction };
};

export const resetUnauthorizedError = () => {
    return { type: RESET_UNAUTHORIZED_ERROR };
};
