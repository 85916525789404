import { combineReducers } from 'redux';
import * as systemActions from '../actions/systemActions';
import { defaultLocale, getLocaleFromLanguage } from '../../../../common/locale';

export const initialState = {
    locale: defaultLocale(),
    date: new Date(),
    covers: 2,
    time: 1200,
};

export const locale = (state = initialState.locale, action = {}) => {
    if (action.type === systemActions.SET_LOCALE) {
        let locale = action.locale;
        if (action.locale.length === 2) {
            locale = getLocaleFromLanguage(locale) || defaultLocale();
        }
        return locale;
    }
    return state;
};

export const time = (state = initialState.time, action = {}) => {
    switch (action.type) {
        case systemActions.SET_TIME:
            return action.time;
        case systemActions.RESET_SEARCH_DETAILS:
            return initialState.time;
        default:
            return state;
    }
};

export const covers = (state = initialState.covers, action = {}) => {
    switch (action.type) {
        case systemActions.SET_COVERS:
            return action.covers;
        case systemActions.RESET_SEARCH_DETAILS:
            return initialState.covers;
        default:
            return state;
    }
};

export const date = (state = initialState.date, action = {}) => {
    switch (action.type) {
        case systemActions.SET_DATE:
            return action.date;
        case systemActions.RESET_SEARCH_DETAILS:
            return initialState.date;
        default:
            return state;
    }
};

export default combineReducers({
    locale,
    time,
    date,
    covers,
});
