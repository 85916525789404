import * as utilities from '../utilities/utilities';

export default class BaseDataObject {
    constructor(o) {
        // clone
        this.ID = o && o.hasOwnProperty('ID') ? o.ID : 0;

        // remote object
        if (this.ID === 0) {
            this.ID = o && o.hasOwnProperty('id') && typeof o.id !== 'function' ? o.id : 0;
        }

        // new object
        if (this.ID === 0) {
            this.guid = o && o.hasOwnProperty('guid') && o.guid ? o.guid : utilities.createGuid();
        }
    }

    id() {
        if (this.ID !== 0) return this.ID;
        if (!this.hasOwnProperty('guid') || !this.guid) this.guid = utilities.createGuid();
        return this.guid;
    }
}
