import Urls from '../../constants/urls';
import * as bookingActions from '../actions/bookingActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === bookingActions.GET_BOOKINGS) {
        try {
            const response = await axios.get(Urls.GET_BOOKINGS);

            return dispatch(bookingActions.getBookingsResponse(response.data));
        } catch (error) {
            Logger.error(Urls.GET_BOOKINGS, error.response);
            return dispatch(bookingActions.getBookingsError(error.response));
        }
    }
};
