export const GET_AVAILABILITIES = 'MWEB/GET_AVAILABILITIES';
export const GET_AVAILABILITIES_RESPONSE = 'MWEB/GET_AVAILABILITIES_RESPONSE';
export const GET_AVAILABILITIES_ERROR = 'MWEB/GET_AVAILABILITIES_ERROR';

export const getAvailabilities = (restaurantIds = [], date) => ({
    type: GET_AVAILABILITIES,
    restaurantIds,
    date,
});

export const getAvailabilitiesResponse = (response, restaurantIds = [], date) => ({
    type: GET_AVAILABILITIES_RESPONSE,
    response,
    restaurantIds,
    date,
});

export const getAvailabilitiesError = (error, restaurantIds = [], date) => ({
    type: GET_AVAILABILITIES_ERROR,
    error,
    restaurantIds,
    date,
});
