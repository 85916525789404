import SwissBoldCondensedWOFF2 from './Swiss-BoldCondensed.woff2';
import SwissBoldCondensedWOFF from './Swiss-BoldCondensed.woff';
import SwissBoldCondensedTTF from './Swiss-BoldCondensed.ttf';
import SwissBoldCondensedEOT from './Swiss-BoldCondensed.eot';
import SwissBoldCondensedOTF from './Swiss-BoldCondensed.otf';

export const SwissBoldCondensed = {
    fontFamily: 'swiss-bold',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `local('swiss-bold'), url(${SwissBoldCondensedTTF}) format('ttf'), url(${SwissBoldCondensedWOFF2}) format('woff2'),url(${SwissBoldCondensedWOFF}) format('woff'),url(${SwissBoldCondensedEOT}) format('eot'),url(${SwissBoldCondensedOTF}) format('otf')`,
};
