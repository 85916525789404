import * as locationActions from '../actions/locationActions';
import { getQueryString } from '../../utilities/utilities';
import { LATITUDE_QUERY_STRING_KEY, LONGITUDE_QUERY_STRING_KEY } from '../../constants/constants';

export default ({ dispatch }) => next => action => {
    next(action);
    if (action.type === locationActions.GET_INITIAL_LOCATION) {
        const latFromUrl = getQueryString(LATITUDE_QUERY_STRING_KEY);
        const longFromUrl = getQueryString(LONGITUDE_QUERY_STRING_KEY);
        const shouldUseLocationFromUrl = Boolean(latFromUrl && longFromUrl);

        if (shouldUseLocationFromUrl) {
            const location = {
                latitude: latFromUrl,
                longitude: longFromUrl,
            };
            return dispatch(locationActions.setSelectedLocation(location));
        }

        return new Promise(success =>
            navigator.geolocation.getCurrentPosition(
                location => {
                    dispatch(locationActions.setDeviceLocation(location));
                    dispatch(locationActions.useDeviceLocationAsSearchLocation());
                    success();
                },
                () => {
                    dispatch(locationActions.setDeviceLocationDenied());
                    success();
                }
            )
        );
    }
};
