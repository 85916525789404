import DiningIdentifier from './diningIdentifier';
import DiningSessionType from './diningSessionType';
import SessionMapStateEnum from '../constants/sessionMapStateEnum';

export default class DiningSession {
    constructor(diningSession) {
        this.diningIdentifier =
            diningSession && diningSession.hasOwnProperty('diningIdentifier') && diningSession.diningIdentifier
                ? DiningIdentifier.fromJson(diningSession.diningIdentifier)
                : null;

        this.sessionMapState =
            diningSession && diningSession.hasOwnProperty('sessionMapState') ? diningSession.sessionMapState : 0;

        this.sessionType =
            diningSession && diningSession.hasOwnProperty('sessionType') && diningSession.sessionType
                ? DiningSessionType.fromJson(diningSession.sessionType)
                : null;
    }

    getPosCheckId() {
        try {
            return this.diningIdentifier.posId;
        } catch (error) {
            console.warn(error);
        }
        return '';
    }

    getTableNumber() {
        try {
            if (this.sessionType.typeId !== -1) {
                return this.sessionType.typeId;
            }
        } catch (error) {
            console.warn(error);
        }
        return '';
    }

    hasTableNumber() {
        try {
            if (this.sessionType.typeId > 0) {
                return true;
            }
        } catch (error) {
            console.warn(error);
        }
        return false;
    }

    sessionMapStateAsString() {
        return SessionMapStateEnum[this.sessionMapState];
    }

    clone() {
        return DiningSession.fromJson(this);
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new DiningSession(json);
    }
}
