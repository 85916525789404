import BaseDataObject from './baseDataObject';

export default class User extends BaseDataObject {
    constructor(user) {
        super(user);
        this.firstName = user && user.hasOwnProperty('firstName') ? user.firstName : '';
        this.lastName = user && user.hasOwnProperty('lastName') ? user.lastName : '';
    }

    getName() {
        return this.firstName + ' ' + this.lastName;
    }

    clone() {
        return User.fromJson(this);
    }

    static create() {
        return new User();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new User(json);
    }
}
