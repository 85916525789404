import Urls from '../../constants/urls';
import * as guestActions from '../actions/guestActions';
import axios from 'axios';
import Logger from '../../utilities/logger';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === guestActions.UPDATE_GUEST_DETAILS) {
        try {
            const response = await axios.put(Urls.PUT_GUEST_PROFILE, action.guest.toSaveObject());
            return dispatch(guestActions.updateGuestDetailsResponse(response.data));
        } catch (error) {
            Logger.error(Urls.PUT_GUEST_PROFILE, error.response);
            return dispatch(guestActions.updateGuestDetailsError(error.response));
        }
    }
};
