export const GET_NEARBY_RESTAURANTS = 'GET_NEARBY_RESTAURANTS';
export const GET_NEARBY_RESTAURANTS_RESPONSE = 'GET_NEARBY_RESTAURANTS_RESPONSE';
export const GET_NEARBY_RESTAURANTS_ERROR = 'GET_NEARBY_RESTAURANTS_ERROR';

export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_RESPONSE = 'GET_RESTAURANTS_RESPONSE';
export const GET_RESTAURANTS_ERROR = 'GET_RESTAURANTS_ERROR';

export const RESET_REMOTING_STATE = 'RESET_REMOTING_STATE_RESTAURANTS';

export const resetRemotingState = () => {
    return { type: RESET_REMOTING_STATE };
};

export const getNearbyRestaurants = (lat, long, rangeInMeters) => {
    return { type: GET_NEARBY_RESTAURANTS, lat, long, rangeInMeters };
};
export const getNearbyRestaurantsResponse = response => {
    return { type: GET_NEARBY_RESTAURANTS_RESPONSE, response };
};
export const getNearbyRestaurantsError = error => {
    return { type: GET_NEARBY_RESTAURANTS_ERROR, error };
};

export const getRestaurants = search => {
    return { type: GET_RESTAURANTS, search };
};
export const getRestaurantsResponse = response => {
    return { type: GET_RESTAURANTS_RESPONSE, response };
};
export const getRestaurantsError = error => {
    return { type: GET_RESTAURANTS_ERROR, error };
};
