import { combineReducers } from 'redux';
import * as uiActions from '../actions/uiActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import routes from '../../constants/routes';

export const initialState = {
    backButtonVisible: false,
    selectedRestaurantOnMap: null,
    selectedSlot: null,
};

export const selectedSlot = (state = initialState.selectedSlot, action = {}) => {
    switch (action.type) {
        case uiActions.SELECT_SLOT:
            return action.slot.clone();
        default:
            return state;
    }
};

export const selectedRestaurantOnMap = (state = initialState.selectedRestaurantOnMap, action = {}) => {
    switch (action.type) {
        case uiActions.SELECT_SLOT:
            return action.restaurant.restaurantId;
        case uiActions.SELECT_RESTAURANT_ON_MAP:
            return action.restaurantId;
        default:
            return state;
    }
};

export const backButtonVisible = (state = initialState.backButtonVisible, action = {}) => {
    if (action.type === LOCATION_CHANGE) {
        switch (action.payload.location.pathname) {
            case routes.MAP_SEARCH.path:
            case routes.HOME.path:
            case routes.UPDATE_INFO.path:
            case routes.BOOKINGS.path:
            case routes.CONFIRMATION.path:
                return false;
            case routes.CREATE_BOOKING.path:
            case routes.RESTAURANT_DETAIL.path:
            case routes.SELECT_LOCATION.path:
                return true;
            default:
                return state;
        }
    }
    return state;
};

export default combineReducers({
    selectedSlot,
    selectedRestaurantOnMap,
    backButtonVisible,
});
