import { getConfig } from '../../../config';
import fetchWithTimeout from './fetchWithTimeout';
import * as storage from './storage';

export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';

export function getSecurityApiUrl() {
    return getConfig().securityGatewayApiUrl;
}

export function getGatewayApiUrl() {
    return getConfig().webGatewayApiUrl;
}

export function getWebSocketApiUrl() {
    return getConfig().webSocketApiUrl;
}

export function getClientSideTimoutMs() {
    return getConfig().clientSideTimoutMs || 20000;
}

export function getS3bucketUrl() {
    if (getConfig().hasOwnProperty('s3bucketUrl')) {
        return getConfig().s3bucketUrl;
    }
    console.warn('no s3 bucket url found in config');
}

export function getProductImagesUrl() {
    return getS3bucketUrl() + 'products/';
}

export function getCategoryImagesUrl() {
    return getS3bucketUrl() + 'categories/';
}

export function signedIn(store) {
    return store.getState().systemReducer.signedIn;
}

const __jsonHeaders = () => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ClientId: getConfig().securityGatewayClientId,
    };

    if (storage.hasToken) {
        let token = storage.getToken();
        headers['Authorization'] = 'Bearer ' + token;
    }

    return headers;
};

const __jsonGetHeaders = () => {
    let headers = {
        Accept: 'application/json',
        ClientId: getConfig().securityGatewayClientId,
    };

    if (storage.hasToken) {
        let token = storage.getToken();
        headers['Authorization'] = 'Bearer ' + token;
    }
    return headers;
};

export const jsonGet = () => {
    return {
        method: 'GET',
        headers: __jsonGetHeaders(),
        mode: 'cors',
        credentials: 'same-origin',
    };
};

export const jsonRequest = (httpVerb = HTTP_POST, jsonString) => {
    return {
        method: httpVerb,
        headers: __jsonHeaders(),
        mode: 'cors',
        credentials: 'same-origin',
        body: jsonString,
    };
};

export const doGet = (url, callbackFunction, errorFunction) => {
    __jsonRequest(url, HTTP_GET, null, callbackFunction, errorFunction);
};

export const doPost = (url, request, callbackFunction, errorFunction) => {
    if (typeof request !== 'string') {
        request = JSON.stringify(request);
    }
    __jsonRequest(url, HTTP_POST, request, callbackFunction, errorFunction);
};

export const doPut = (url, request, callbackFunction, errorFunction) => {
    if (typeof request !== 'string') {
        request = JSON.stringify(request);
    }
    __jsonRequest(url, HTTP_PUT, request, callbackFunction, errorFunction);
};

export const doDelete = (url, callbackFunction, errorFunction) => {
    __jsonRequest(url, HTTP_DELETE, '', callbackFunction, errorFunction);
};

export const doDeleteWithRequest = (url, request, callbackFunction, errorFunction) => {
    __jsonRequest(url, HTTP_DELETE, request, callbackFunction, errorFunction);
};

const __jsonRequest = (url, httpVerb, request, callbackFunction, errorFunction) => {
    if (request && Object.prototype.toString.call(request) === '[object Object]') {
        request = JSON.stringify(request);
    }
    let statusCode;

    let isGet = request === null && httpVerb === HTTP_GET;
    fetchWithTimeout(url, isGet ? jsonGet() : jsonRequest(httpVerb, request), getClientSideTimoutMs())
        .then(function(response) {
            statusCode = response.status;
            let contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .then(function(data) {
            // check if we do have a 2xx response code
            if (('' + statusCode).slice(0, 1) === '2') {
                callbackFunction(data);
            } else {
                //errorMessage = `${errorMessage} ${data ? JSON.stringify(data) : ''}`;
                errorFunction(prettyError(statusCode, data, isGet));
                handleRemotingError(data);
            }
        })
        .catch(function(error) {
            errorFunction(prettyError(600, error.message, isGet));
            handleRemotingError(error);
        });
};

export function prettyError(status, response = null) {
    console.warn('prettyError', status, response);
    let errorMessages = [];

    function getErrorMessageFromErrorObject(o) {
        var message = '';

        for (let key in o) {
            if (o.hasOwnProperty(key)) {
                message += key + ':' + o[key] + '\n';
            }
        }
        return message;
    }

    if (response) {
        if (Object.prototype.toString.call(response) === '[object Array]') {
            errorMessages = response.map(r => getErrorMessageFromErrorObject(r));
        }
        if (Object.prototype.toString.call(response) === '[object String]') {
            errorMessages.push(response);
        }
        if (Object.prototype.toString.call(response) === '[object Object]') {
            if (response.hasOwnProperty('errorMessage')) errorMessages.push(response['errorMessage']);
            if (response.hasOwnProperty('error_description')) {
                errorMessages.push(response['error_description']);
            }
            if (response.hasOwnProperty('errors')) {
                errorMessages.push(response['error']);
            }
        }
    }

    let errormsg = '';
    if (errorMessages.length) {
        errormsg += '\n' + errorMessages.join('\n');
    }
    let prettyError = {
        statuscode: status,
        message: errormsg,
    }; // I18n.t('error.' + (isGet ? 'load.' : 'save.') + status);
    return prettyError;
}

export function handleRemotingError(error) {
    console.warn(error);
}
