export const RESET_REMOTING_STATE = 'MWEB/RESET_REMOTING_STATE_GUEST';
export const GET_GUEST_DETAILS = 'MWEB/GET_GUEST_DETAILS';
export const GET_GUEST_DETAILS_RESPONSE = 'MWEB/GET_GUEST_DETAILS_RESPONSE';
export const GET_GUEST_DETAILS_ERROR = 'MWEB/GET_GUEST_DETAILS_ERROR';
export const UPDATE_GUEST_DETAILS = 'MWEB/UPDATE_GUEST_DETAILS';
export const UPDATE_GUEST_DETAILS_RESPONSE = 'MWEB/UPDATE_GUEST_DETAILS_RESPONSE';
export const UPDATE_GUEST_DETAILS_ERROR = 'MWEB/UPDATE_GUEST_DETAILS_ERROR';

export const resetRemotingState = () => ({ type: RESET_REMOTING_STATE });

export const getGuestDetails = () => ({ type: GET_GUEST_DETAILS });

export const getGuestDetailsResponse = response => ({
    type: GET_GUEST_DETAILS_RESPONSE,
    response,
});

export const getGuestDetailsError = error => ({
    type: GET_GUEST_DETAILS_ERROR,
    error,
});

export const updateGuestDetails = guest => ({
    type: UPDATE_GUEST_DETAILS,
    guest,
});

export const updateGuestDetailsResponse = response => ({
    type: UPDATE_GUEST_DETAILS_RESPONSE,
    response,
});

export const updateGuestDetailsError = error => ({
    type: UPDATE_GUEST_DETAILS_ERROR,
    error,
});
