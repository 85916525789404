import { push } from 'connected-react-router';
import Urls from '../../constants/urls';
import * as bookingActions from '../actions/bookingActions';
import axios from 'axios';
import Logger from '../../utilities/logger';
import routes from '../../constants/routes';

export default ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === bookingActions.CREATE_BOOKING) {
        try {
            const response = await axios.post(Urls.POST_BOOKING, action.booking.toSaveObject());
            dispatch(bookingActions.createBookingResponse(response.data, action.booking));
            return dispatch(push(`${routes.CONFIRMATION.path}${window.location.search}`));
        } catch (error) {
            Logger.error(Urls.POST_BOOKING, error.response);
            return dispatch(bookingActions.createBookingError(error.response));
        }
    }
};
