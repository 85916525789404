import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import App from './client/AppRoutes';
import configureStore, { history } from './client/broccoli/js/reduxStore';
import * as serviceWorker from './client/common/serviceWorker';
import i18next from 'i18next';
import { setConfig } from './client/config';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as systemActions from './client/mWeb/js/redux/actions/systemActions';
import * as guestActions from './client/mWeb/js/redux/actions/guestActions';
import * as locationActions from './client/mWeb/js/redux/actions/locationActions';
import * as apiHelper from './client/mWeb/js/utilities/apiHelper';
import enGB from './client/common/locale/translations/en-GB';
import enUS from './client/common/locale/translations/en-US';
import deDE from './client/common/locale/translations/de-DE';
import esES from './client/common/locale/translations/es-ES';
import caES from './client/common/locale/translations/ca-ES';
import frFR from './client/common/locale/translations/fr-FR';
import itIT from './client/common/locale/translations/it-IT';
import elGR from './client/common/locale/translations/el-GR';
import nlNL from './client/common/locale/translations/nl-NL';
import ruRU from './client/common/locale/translations/ru-RU';

startMWEBApp();

async function startMWEBApp() {
    const { data: config } = await axios.get('/config');
    setConfig(config);

    const trackingId = config.googleTagManagerId;
    if (trackingId) {
        const tagManagerArgs = {
            gtmId: trackingId,
            dataLayer: {
                userId: apiHelper.getUserId(),
                userProject: 'project',
            },
        };
        window.dataLayer = window.dataLayer || [];
        TagManager.initialize(tagManagerArgs);
    }

    await i18next.use(LanguageDetector).init({
        fallbackLng: 'en-GB',
        detection: { lookupQuerystring: 'lang' },
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: { translation: enGB },
            'en-GB': { translation: enGB },
            'en-US': { translation: enUS },
            de: { translation: deDE },
            'de-DE': { translation: deDE },
            fr: { translation: frFR },
            'fr-FR': { translation: frFR },
            es: { translation: esES },
            'es-ES': { translation: esES },
            ca: { translation: caES },
            'ca-ES': { translation: caES },
            it: { translation: itIT },
            'it-IT': { translation: itIT },
            el: { translation: elGR },
            'el-GR': { translation: elGR },
            nl: { translation: nlNL },
            'nl-NL': { translation: nlNL },
            ru: { translation: ruRU },
            'ru-RU': { translation: ruRU },
        },
    });

    apiHelper.initAxios();

    // Set up redux store
    const store = configureStore(/* provide initial state if any */);
    if (apiHelper.getUserId()) {
        await apiHelper.refreshToken();
        store.dispatch(guestActions.getGuestDetails());
    }
    store.dispatch(systemActions.setLocale(i18next.languages[0]));
    store.dispatch(locationActions.getInitialLocation());

    // Render react app
    ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
