import activeLocales from './activeLocales';

// If Locale is set as only the language i.e. 'en' then the locale needs to be suffixed for some functionality to work correctly i.e. 'en-GB'
// This will map the language to a full language-locale string.
const getLocaleFromLanguage = languageCode => {
    const locale = activeLocales.find(({ value }) => {
        return value.split('-')[0] === languageCode;
    });
    if (locale) {
        return locale.value;
    }
};

export default getLocaleFromLanguage;
