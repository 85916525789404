import * as bookingActions from '../actions/bookingActions';
import { selectedRestaurantPreReservationText } from '../selectors/restaurantSelectors';

export default ({ dispatch, getState }) => next => action => {
    if (action.type === bookingActions.SUBMIT_BOOKING_FORM) {
        const state = getState();
        const preReservationText = selectedRestaurantPreReservationText(state);
        const preReservationTextConfirmed = state.mWebApp.bookingReducer.preReservationConfirmed;
        if (preReservationText && !preReservationTextConfirmed) {
            dispatch(bookingActions.showPreReservationText());
        } else {
            dispatch(bookingActions.hidePreReservationText());
            dispatch(bookingActions.createBooking(action.booking));
        }
    }
    next(action);
};
