export default class DiningIdentifier {
    constructor(diningIdentifier) {
        this.posId = diningIdentifier && diningIdentifier.hasOwnProperty('posId') ? diningIdentifier.posId : '';
        this.externalStoreId =
            diningIdentifier && diningIdentifier.hasOwnProperty('externalStoreId')
                ? diningIdentifier.externalStoreId
                : '';
        this.storeId = diningIdentifier && diningIdentifier.hasOwnProperty('storeId') ? diningIdentifier.storeId : '';
        this.broccoliId =
            diningIdentifier && diningIdentifier.hasOwnProperty('broccoliId') ? diningIdentifier.broccoliId : '';
    }

    clone() {
        return DiningIdentifier.fromJson(this);
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new DiningIdentifier(json);
    }
}
