export default {
    AwaitingPosId: 0,
    AwaitingTypeValidation: 1,
    ReadyForOrder: 2,
    Closed: 3,
    0: 'AwaitingPosId',
    1: 'AwaitingTypeValidation',
    2: 'ReadyForOrder',
    3: 'Closed',
};
