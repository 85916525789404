import { push } from 'connected-react-router';
import { tableActions, basketActions, systemActions } from '../../actions/index';
import SignalRHelper from '../../../utilities/signalRHelper';
import * as reduxStoreHelper from '../../../utilities/reduxStoreHelper';
import routes from '../../../constants/routes';
import * as storage from '../../../utilities/storage';
import * as utilities from '../../../utilities/utilities';

const REGISTERED_ACTION_TYPES = [
    tableActions.START_TABLE,
    tableActions.JOIN_TABLE,
    tableActions.SYNCED_TABLE,
    tableActions.TABLE_CONFIRMED,
    tableActions.END_TABLE,
];

let dispatch;

let reduxStore = null;

const socketConnectionMethods = {
    startTable: 'starttable',
    tableStarted: 'tablestarted',
    startTableError: 'starttableerror',
    joinTable: 'jointable',
    tableJoined: 'tablejoined',
    joinTableError: 'jointableerror',
    syncedTable: 'syncedtable',
    endTable: 'endtable',
};

const registerGenericSocketEvents = () => {
    SignalRHelper.getConnectionHub().on(socketConnectionMethods.syncedTable, table => {
        let user = reduxStoreHelper.getUser(reduxStore);
        console.log('tableMiddleware.syncedTable', table);
        dispatch(tableActions.syncedTable(table, user));
    });

    SignalRHelper.getConnectionHub().on(socketConnectionMethods.endTable, () => {
        if (dispatch) {
            dispatch(push(routes.RESTAURANT.path));
        }
    });

    SignalRHelper.getConnectionHub().on(socketConnectionMethods.tableJoined, (userId, table, basket, diningSession) => {
        let user = reduxStoreHelper.getUser(reduxStore);
        console.log(`tableMiddleware.tableJoined by ${userId}`, table);

        storage.setBroccoliId(diningSession.diningIdentifier.broccoliId);
        dispatch(systemActions.syncedDiningSession(diningSession));
        dispatch(tableActions.joinTableResponse(table, userId));

        if (basket) {
            dispatch(basketActions.loadBasket(basket, reduxStoreHelper.getMenuItemLookup(reduxStore)));
        } else {
            dispatch(basketActions.createBasket(table.id, diningSession.diningIdentifier.broccoliId));
        }
        // only if not owner and freshly joined
        if (!reduxStoreHelper.isTableOwner(reduxStore) && userId === user.id()) {
            dispatch(push(routes.MENU.path));
        }
    });

    SignalRHelper.getConnectionHub().on(socketConnectionMethods.joinTableError, error => {
        if (dispatch) {
            console.log(error);
            // TODO user real errors BROC-524
            dispatch(tableActions.joinTableError('Table code not found'));
        }
    });

    SignalRHelper.getConnectionHub().on(socketConnectionMethods.tableStarted, (table, diningSession) => {
        storage.setBroccoliId(diningSession.diningIdentifier.broccoliId);
        dispatch(systemActions.syncedDiningSession(diningSession));
        dispatch(tableActions.startTableResponse(table));
        dispatch(basketActions.createBasket(table.id, diningSession.diningIdentifier.broccoliId));
        dispatch(push(routes.TABLE_CODE.path));
    });

    SignalRHelper.getConnectionHub().on(socketConnectionMethods.startTableError, error => {
        dispatch(tableActions.startTableError(error));
    });
};

export default store => next => action => {
    dispatch = store.dispatch;
    reduxStore = store;
    function startTable(action) {
        registerGenericSocketEvents();

        if (action.userId !== -1 && action.storeId !== -1) {
            let promise = SignalRHelper.getConnectionHub().invoke(
                socketConnectionMethods.startTable,
                action.storeId,
                action.userId
            );
            promise
                .then(success => {
                    console.log('starttable success', success);
                })
                .catch(error => {
                    console.log('starttable error', error);
                    if (utilities.isUnauthorizedError(error.message)) {
                        dispatch(systemActions.unauthorizedError());
                    }
                    dispatch(tableActions.startTableError(error));
                });
        }
    }

    function joinTable(action) {
        registerGenericSocketEvents();

        if (action.userId !== -1 && action.storeId !== -1 && action.posCheckId !== -1) {
            let promise = SignalRHelper.getConnectionHub().invoke(
                socketConnectionMethods.joinTable,
                action.storeId,
                action.userId,
                action.posCheckId
            );
            promise
                .then(success => {
                    console.log('jointable success', success);
                })
                .catch(error => {
                    console.log('jointable error', error);
                    if (utilities.isUnauthorizedError(error.message)) {
                        dispatch(systemActions.unauthorizedError());
                    }
                    dispatch(tableActions.joinTableError(error));
                });
        }
    }

    function endTable(action) {
        registerGenericSocketEvents();

        let promise = SignalRHelper.getConnectionHub().invoke('endtable', action.userId, action.tableId);
        promise
            .then(success => {
                console.log('endtable success', success);
            })
            .catch(error => {
                console.log('endtable error', error);
                dispatch(tableActions.endTableError(error));
                if (utilities.isUnauthorizedError(error.message)) {
                    dispatch(systemActions.unauthorizedError(routes.SELECT_RESTAURANT));
                }
            });
    }

    function executeAction(action) {
        switch (action.type) {
            case tableActions.START_TABLE:
                startTable(action);
                next(action);
                break;

            case tableActions.JOIN_TABLE:
                joinTable(action);
                next(action);
                break;

            case tableActions.END_TABLE:
                endTable(action);
                next(action);
                break;

            default:
                next(action);
        }
    }

    if (REGISTERED_ACTION_TYPES.indexOf(action.type) !== -1) {
        executeAction(action);
    } else {
        return next(action);
    }
};
