const routes = {
    HOME: {
        path: '/marriott/table-reservation',
    },
    MAP_SEARCH: {
        path: '/marriott/table-reservation/map-search',
    },
    RESTAURANT_DETAIL: {
        path: '/marriott/table-reservation/restaurant-detail',
    },
    BOOKINGS: {
        path: '/marriott/table-reservation/bookings',
    },
    UPDATE_INFO: {
        path: '/marriott/table-reservation/update-info',
    },
    CREATE_BOOKING: {
        path: '/marriott/table-reservation/create-booking',
    },
    CONFIRMATION: {
        path: '/marriott/table-reservation/confirmation',
    },
    SELECT_LOCATION: {
        path: '/marriott/table-reservation/select-location',
    },
};

export default routes;
