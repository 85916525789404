import { push } from 'connected-react-router';
import { basketActions, menuActions, tableActions, systemActions } from '../../actions';
import SignalRHelper from '../../../utilities/signalRHelper';
import * as apiHelper from '../../../utilities/apiHelper';
import * as storage from '../../../utilities/storage';
import * as utilities from '../../../utilities/utilities';
import * as reduxStoreHelper from '../../../utilities/reduxStoreHelper';
import routes from '../../../constants/routes';
import SessionMapStateEnum from '../../../constants/sessionMapStateEnum';
import { once } from 'redux-when';
import queryString from 'query-string';
import _get from 'lodash/get';
import _find from 'lodash/find';

const REGISTERED_ACTION_TYPES = [
    systemActions.SIGNIN,
    systemActions.GET_DINING_SESSION,
    systemActions.RECOVER_STATE,
    systemActions.START_SOCKET,
    systemActions.RECONNECTED_SOCKET,
    systemActions.RESTART_SOCKET,
    systemActions.SIGNIN_RESPONSE,
    systemActions.SIGNUP,
    systemActions.SIGNUP_RESPONSE,
    systemActions.UNAUTHORIZED_ERROR,
    systemActions.FORCE_SIGN_OUT,
    systemActions.CLEAR_DINING_SESSION,
    systemActions.FINISH_DINING_SESSION,
];

let dispatch;
let signinCount = 0;
let reduxStore = null;

let socketConnection;
const socketConnectionMethods = {
    syncedDiningSession: 'synceddiningsession',
    recoverState: 'recoverstate',
    stateRecovered: 'staterecovered',
    reconnect: 'reconnect',
    reconnectError: 'reconnecterror',
};
const registerSocketEvents = () => {
    socketConnection.on(socketConnectionMethods.syncedDiningSession, diningSession => {
        console.log('systemMiddleware.syncedDiningSession', diningSession);
        dispatch(systemActions.syncedDiningSession(diningSession));
    });

    socketConnection.on(socketConnectionMethods.stateRecovered, (table, basket, store, menus) => {
        console.log('systemMiddleware.stateRecovered');

        dispatch(systemActions.loadStore(store));
        dispatch(tableActions.loadTable(table));
        dispatch(menuActions.getMenusForStoreResponse(menus));

        if (basket) {
            let menuItemLookup = reduxStoreHelper.getMenuItemLookup(reduxStore);
            dispatch(basketActions.loadBasket(basket, menuItemLookup));
        } else {
            let brocId = storage.getBroccoliId();
            dispatch(basketActions.createBasket(table.id, brocId));
        }

        dispatch(push(routes.MENU.path));
    });

    socketConnection.on(socketConnectionMethods.reconnectError, error => {
        if (dispatch) {
            setTimeout(() => dispatch(systemActions.socketError(error)), 1000);
        }
    });
};

const redirectToSuccessRoute = () => {
    const { successRoute } = queryString.parse(_get(reduxStore.getState(), 'router.location.search'));
    const { path = routes.HOME.path } = _find(routes, route => route.path === successRoute) || {};
    dispatch(once(state => state.systemReducer.socketStatus.online, () => push(path)));
};

export default store => next => action => {
    dispatch = store.dispatch;
    reduxStore = store;

    function signin(action) {
        let url = apiHelper.getSecurityApiUrl() + 'login';

        let count = ++signinCount;

        let email = action.email;
        let password = action.password;

        let callbackFunction = function(response) {
            if (count === signinCount) {
                storage.setEmail(email);
                storage.setToken(response.token);
                storage.setUser(response.user);
                dispatch(systemActions.signinResponse(response.user));
                redirectToSuccessRoute();
            }
        };
        let errorFunction = function(error) {
            if (count === signinCount) {
                setTimeout(function() {
                    dispatch(systemActions.signinError(error));
                }, 1000);
            }
        };
        apiHelper.doPost(url, { email, password }, callbackFunction, errorFunction);
    }

    function signinResponse() {
        dispatch(systemActions.restartSocket());
    }

    function signup(action) {
        console.log('Registering user with username ' + action.email);

        let successCallBack = function(response) {
            console.log(response);
            dispatch(systemActions.signupResponse(action.email, action.password));
            // redirectToSuccessRoute();
        };

        let errorCallback = function(error) {
            dispatch(systemActions.signupError(error));
        };

        let url = apiHelper.getSecurityApiUrl() + 'register';
        apiHelper.doPost(
            url,
            {
                phonenumber: action.phone,
                firstname: action.firstname,
                lastname: action.lastname,
                email: action.email,
                password: action.password,
            },
            successCallBack,
            errorCallback
        );
    }

    function signupResponse(action) {
        dispatch(systemActions.signin(action.email, action.password));
    }

    function recoverState(action) {
        console.log('systemMiddleware.recoverState');

        let promise = socketConnection.invoke(socketConnectionMethods.recoverState, action.broccoliId, action.userId);
        promise
            .then(success => {
                console.log('recoverstate success', success);
            })
            .catch(error => {
                console.log('recoverstate error', error);
                if (utilities.isUnauthorizedError(error.message)) {
                    dispatch(systemActions.unauthorizedError());
                }
                dispatch(systemActions.recoverStateError(error));
            });
    }

    function reconnectedSocket() {
        console.log('systemMiddleware.reconnectedSocket');

        let broccoliId = reduxStoreHelper.getBroccoliId(reduxStore);
        let user = reduxStoreHelper.getUser(reduxStore);

        // only reconnect to group if broccoli session is in play
        if (broccoliId) {
            let promise = socketConnection.invoke(socketConnectionMethods.reconnect, broccoliId, user.id());
            promise
                .then(success => {
                    console.log('reconnect success', success);
                })
                .catch(error => {
                    console.log('reconnect error', error);
                    if (utilities.isUnauthorizedError(error.message)) {
                        dispatch(systemActions.unauthorizedError());
                    }
                });
        }
    }

    function restartSocket() {
        SignalRHelper.restartConnection();
    }

    function startSocket() {
        let restartSocket = () => {
            dispatch(systemActions.restartSocket());
        };
        let reconnectedSocket = () => {
            dispatch(systemActions.reconnectedSocket());
        };
        let socketError = error => {
            dispatch(systemActions.socketError(error));
        };
        let socketStarted = () => {
            dispatch(systemActions.socketStarted());
        };

        socketConnection = SignalRHelper.initialiseConnection(
            restartSocket,
            socketStarted,
            reconnectedSocket,
            socketError
        );
        registerSocketEvents();
    }

    function getDiningSession(action) {
        let url = `${apiHelper.getGatewayApiUrl()}diningsession/api/v1/diningsession/${action.broccoliId}`;

        let callbackFunction = function(response) {
            dispatch(systemActions.getDiningSessionResponse(response));
            if (SessionMapStateEnum[response.sessionMapState] === 'Closed') {
                // clear app of expired broccoli session
                storage.setBroccoliId();
                dispatch(systemActions.clearDiningSession());
                dispatch(push(routes.PAGE_STORES.path));
            } else {
                dispatch(systemActions.recoverState(action.broccoliId, action.userId));
            }
        };
        let errorFunction = function(error) {
            setTimeout(function() {
                dispatch(systemActions.getDiningSessionError(error));
                if (error.statuscode === 401) {
                    dispatch(systemActions.unauthorizedError('getDiningSession'));
                }
            }, 1000);
        };
        apiHelper.doGet(url, callbackFunction, errorFunction);
    }

    function finishDiningSession() {
        dispatch(systemActions.clearDiningSession());
        dispatch(push(routes.HOME.path));
    }

    function unauthorizedError(action) {
        storage.setUser();
        storage.setToken();
        // what to do with current socket which thinks it's secure?
        dispatch(systemActions.signedInNextAction(action.signedInNextAction || reduxStoreHelper.getPage(reduxStore)));
    }

    function forceSignOut() {
        storage.setUser();
        storage.setToken();
        dispatch(systemActions.restartSocket());
    }

    function clearDiningSession() {
        storage.setBroccoliId();
    }

    function executeAction(action) {
        switch (action.type) {
            case systemActions.SIGNIN:
                signin(action);
                next(action);
                break;

            case systemActions.SIGNIN_RESPONSE:
                signinResponse();
                next(action);
                break;

            case systemActions.SIGNUP:
                signup(action);
                next(action);
                break;

            case systemActions.SIGNUP_RESPONSE:
                signupResponse(action);
                next(action);
                break;

            case systemActions.GET_DINING_SESSION:
                getDiningSession(action);
                next(action);
                break;

            case systemActions.RECOVER_STATE:
                recoverState(action);
                next(action);
                break;

            case systemActions.START_SOCKET:
                startSocket(action);
                next(action);
                break;

            case systemActions.RECONNECTED_SOCKET:
                reconnectedSocket(action);
                next(action);
                break;

            case systemActions.RESTART_SOCKET:
                restartSocket(action);
                next(action);
                break;

            case systemActions.UNAUTHORIZED_ERROR:
                unauthorizedError(action);
                next(action);
                break;

            case systemActions.FORCE_SIGN_OUT:
                forceSignOut(action);
                next(action);
                break;

            case systemActions.FINISH_DINING_SESSION:
                finishDiningSession(action);
                next(action);
                break;

            case systemActions.CLEAR_DINING_SESSION:
                clearDiningSession(action);
                next(action);
                break;

            default:
                next(action);
        }
    }

    if (REGISTERED_ACTION_TYPES.indexOf(action.type) !== -1) {
        executeAction(action);
    } else {
        return next(action);
    }
};
