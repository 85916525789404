import { push } from 'connected-react-router';
import * as restaurantActions from '../actions/restaurantActions';
import * as bookingActions from '../actions/bookingActions';
import * as uiActions from '../actions/uiActions';
import routes from '../../constants/routes';

export default ({ dispatch }) => next => action => {
    if (action.type === uiActions.SELECT_SLOT) {
        const selectedRestaurantId = action.restaurant.restaurantId;
        dispatch(bookingActions.getExtraReservationFields(selectedRestaurantId));
        dispatch(restaurantActions.getRestaurant(selectedRestaurantId));
        dispatch(push(`${routes.CREATE_BOOKING.path}${window.location.search}`));
    }
    next(action);
};
