export default [
    {
        key: 'English (British)',
        value: 'en-GB',
        termsOfUse: 'https://www.marriott.co.uk/about/terms-of-use.mi',
        default: true,
    },
    { key: 'English (American)', value: 'en-US', termsOfUse: 'https://www.marriott.co.uk/about/terms-of-use.mi' },
    { key: 'French', value: 'fr-FR', termsOfUse: 'https://www.marriott.fr/a-propos/conditions-utilisation-france.mi' },
    { key: 'German', value: 'de-DE', termsOfUse: 'https://www.marriott.de/ueber/datenschutzerklarung-deutschland.mi' },
    {
        key: 'Spanish',
        value: 'es-ES',
        termsOfUse: 'https://www.espanol.marriott.com/sobre-marriott/condiciones-de-uso.mi',
    },
    { key: 'Catalan', value: 'ca-ES', termsOfUse: 'https://www.marriott.co.uk/about/terms-of-use.mi' },
    { key: 'Italian', value: 'it-IT', termsOfUse: 'https://www.marriott.it/loyalty/terms/default.mi' },
    { key: 'Greek', value: 'el-GR', termsOfUse: 'https://www.marriott.co.uk/about/terms-of-use.mi' },
    { key: 'Netherlands', value: 'nl-NL', termsOfUse: 'https://www.marriott.co.uk/about/terms-of-use.mi' },
    { key: 'Russian', value: 'ru-RU', termsOfUse: 'https://www.marriott.com.ru/about/terms-of-use.mi' },
];
