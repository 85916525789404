import { defaultLocale, getLocaleFromLanguage } from '../../../common/locale';

export function getValue(identifier, defaultValue = null) {
    if (localStorage) {
        try {
            if (defaultValue !== null) return localStorage.getItem(identifier) || defaultValue;
            const a = localStorage.getItem(identifier);
            return a;
        } catch (error) {
            console.warn(error);
        }
    }
}

export function setValue(identifier, value) {
    if (localStorage) {
        try {
            localStorage.setItem(identifier, value);
        } catch (error) {
            console.warn(error);
        }
    }
}

const AUTH_TOKEN = 'mweb-token';
const LOCALE = 'i18nextLng';

export function getLocale() {
    let locale = getValue(LOCALE);
    if (locale && locale.length === 2) {
        locale = getLocaleFromLanguage(locale) || defaultLocale();
    }
    return locale;
}

export function getAuthToken() {
    return getValue(AUTH_TOKEN);
}

export function setAuthToken(token) {
    return setValue(AUTH_TOKEN, token);
}

export function getMapPosition() {
    return {
        lat: getValue('mweb-map-lat'),
        lng: getValue('mweb-map-lng'),
        zoom: getValue('mweb-map-zoom'),
    };
}

export function setMapPosition(lat = null, lng = null, zoom = null) {
    //console.log('store map position', lat, lng, zoom);

    setValue('mweb-map-lat', lat);
    setValue('mweb-map-lng', lng);
    setValue('mweb-map-zoom', zoom);
}

export function setMapPositionLeaflet(center, zoom) {
    setMapPosition(center.lat, center.lng, zoom);
}

export function setMapPositionGoogleMaps(center, zoom) {
    setMapPosition(center.lat(), center.lng(), zoom);
}

export function clearMapPosition() {
    setMapPosition();
}

export function getFavouriteRestaurants() {
    let a = getValue('mweb-tablres-favourites', '').split(',');
    a = a.map(id => parseInt(id, 10)).filter(id => !isNaN(id));
    return a;
}

export function setFavouriteRestaurants(favourites = []) {
    return setValue('mweb-tablres-favourites', favourites.join(','));
}

export function addLocation(location) {
    try {
        if (location.name) {
            let locations = getLocations();
            locations = locations.filter(l => l.name !== location.name);
            locations.unshift(location);
            setValue('mweb-previous-locations', JSON.stringify(locations));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function getLocations() {
    let locations = [];
    try {
        const l = getValue('mweb-previous-locations', '');
        if (l) locations = JSON.parse(l);
    } catch (error) {
        console.warn(error);
    }
    return locations;
}
