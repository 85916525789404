export default class DiningSessionType {
    constructor(diningSessionType) {
        this.typeId = diningSessionType && diningSessionType.hasOwnProperty('typeId') ? diningSessionType.typeId : -1;

        this.sessionType =
            diningSessionType && diningSessionType.hasOwnProperty('sessionType') ? diningSessionType.sessionType : '';

        this.isInValidState =
            diningSessionType && diningSessionType.hasOwnProperty('isInValidState')
                ? diningSessionType.isInValidState
                : false;
    }

    clone() {
        return DiningSessionType.fromJson(this);
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new DiningSessionType(json);
    }
}
