import ProximaNovaRegularWOFF2 from './proxima-nova-regular.woff2';
import ProximaNovaRegularWOFF from './proxima-nova-regular.woff';
import ProximaNovaRegularTTF from './proxima-nova-regular.ttf';
import ProximaNovaRegularEOT from './proxima-nova-regular.eot';
import ProximaNovaRegularOTF from './proxima-nova-regular.otf';

import ProximaNovaSemiboldWOFF2 from './proxima-nova-semibold.woff2';
import ProximaNovaSemiboldWOFF from './proxima-nova-semibold.woff';
import ProximaNovaSemiboldTTF from './proxima-nova-semibold.ttf';
import ProximaNovaSemiboldEOT from './proxima-nova-semibold.eot';
import ProximaNovaSemiboldOTF from './proxima-nova-semibold.otf';

export const ProximaNovaRegular = {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `local('proxima-nova'), url(${ProximaNovaRegularTTF}) format('ttf'), url(${ProximaNovaRegularWOFF2}) format('woff2'),url(${ProximaNovaRegularWOFF}) format('woff'),url(${ProximaNovaRegularEOT}) format('eot'),url(${ProximaNovaRegularOTF}) format('otf')`,
};

export const ProximaNovaSemibold = {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 600,
    src: `local('proxima-nova'), url(${ProximaNovaSemiboldTTF}) format('ttf'), url(${ProximaNovaSemiboldWOFF2}) format('woff2'),url(${ProximaNovaSemiboldWOFF}) format('woff'),url(${ProximaNovaSemiboldEOT}) format('eot'),url(${ProximaNovaSemiboldOTF}) format('otf')`,
};
