export const SET_DEVICE_LOCATION = 'MWEB/SET_DEVICE_LOCATION';
export const SET_DEVICE_LOCATION_DENIED = 'MWEB/SET_DEVICE_LOCATION_DENIED';
export const SET_SELECTED_LOCATION = 'MWEB/SET_SELECTED_LOCATION';
export const USE_DEVICE_LOCATION_AS_SEARCH_LOCATION = 'MWEB/USE_DEVICE_LOCATION_AS_SEARCH_LOCATION';
export const GET_INITIAL_LOCATION = 'MWEB/GET_INITIAL_LOCATION';
export const QUERY_FOR_PLACE = 'MWEB/QUERY_FOR_PLACE';
export const QUERY_FOR_PLACE_RESPONSE = 'MWEB/QUERY_FOR_PLACE_RESPONSE';
export const QUERY_FOR_PLACE_ERROR = 'MWEB/QUERY_FOR_PLACE_ERROR';
export const CLEAR_QUERY = 'MWEB/QUERY_FOR_PLACE_CLEAR';
export const SELECT_PLACE = 'MWEB/SELECT_PLACE';

export const setDeviceLocation = location => ({ type: SET_DEVICE_LOCATION, location });

export const setDeviceLocationDenied = () => ({ type: SET_DEVICE_LOCATION_DENIED });

export const setSelectedLocation = location => ({ type: SET_SELECTED_LOCATION, location });

export const useDeviceLocationAsSearchLocation = () => ({ type: USE_DEVICE_LOCATION_AS_SEARCH_LOCATION });

export const getInitialLocation = () => ({ type: GET_INITIAL_LOCATION });

export const queryForPlace = queryString => ({ type: QUERY_FOR_PLACE, queryString });

export const queryForPlaceResponse = response => ({ type: QUERY_FOR_PLACE_RESPONSE, response });

export const queryForPlaceError = error => ({ type: QUERY_FOR_PLACE_ERROR, error });

export const clear = () => ({ type: CLEAR_QUERY });

export const selectPlace = place => ({ type: SELECT_PLACE, place });
