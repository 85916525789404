const apiVersion = 'v2.0';

export default class Urls {
    static GET_TOKEN = `/${apiVersion}/mweb/authorization/token/`;

    static GET_GUEST_PROFILE = `/${apiVersion}/mweb/guestprofile/`;
    static PUT_GUEST_PROFILE = `/${apiVersion}/mweb/guestprofile/`;
    static GET_RESTAURANTS_BY_LOCATION = `/${apiVersion}/mweb/restaurantlocation/bydistance/`;
    static GET_RESTAURANT_BY_ID = `/${apiVersion}/mweb/restaurant/`;
    static GET_RESTAURANTS_BY_SEARCHSTRING = `not_implemented`;

    static GET_AVAILBILITY = `/${apiVersion}/mweb/availability/restaurantsavailability`;

    static GET_BOOKINGS = `/${apiVersion}/mweb/guestreservation/getreservations`;
    static POST_BOOKING = `/${apiVersion}/mweb/guestreservation/create`;
    static PUT_BOOKING = `/${apiVersion}/mweb/guestreservation/update`;
    static CANCEL_BOOKING = `/${apiVersion}/mweb/guestreservation/cancel`;
    static GET_EXTRA_FIELDS = `/${apiVersion}/mweb/guestreservation/extrafields/`;
    static LOGGER = '/log';
}
