import { restaurantActions } from '../../actions';
import { getConfig } from '../../../../../config';
import axios from 'axios';
import serviceHeaders from '../../../constants/serviceHeaders';

export default ({ dispatch }) => next => action => {
    next(action);
    if (restaurantActions.GET_NEARBY_RESTAURANTS === action.type) {
        return axios
            .get(
                `${getConfig().webGatewayApiUrl}restaurant/api/v1/store/getStoresNearUser/${action.lat}/${
                    action.long
                }/${action.rangeInMeters}`,
                {
                    headers: serviceHeaders,
                }
            )
            .then(response => dispatch(restaurantActions.getNearbyRestaurantsResponse(response.data)))
            .catch(error => dispatch(restaurantActions.getNearbyRestaurantsError(error.data)));
    }
};
