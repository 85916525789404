import * as availabilityActions from '../actions/availabilityActions';
import * as restaurantActions from '../actions/restaurantActions';
import * as uiActions from '../actions/uiActions';
import routes from '../../constants/routes';
import { push } from 'connected-react-router';

export default ({ dispatch, getState }) => next => action => {
    if (action.type === restaurantActions.SELECT_RESTAURANT) {
        const restaurantId = action.restaurantId;
        const date = getState().mWebApp.systemReducer.date;
        dispatch(restaurantActions.getRestaurant(restaurantId));
        dispatch(uiActions.selectRestaurantOnMap(restaurantId));
        dispatch(availabilityActions.getAvailabilities([restaurantId], date));
        dispatch(push(`${routes.RESTAURANT_DETAIL.path}${window.location.search}`));
    }
    next(action);
};
