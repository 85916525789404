export default class Promotion {
    constructor(promotion) {
        this.id = promotion && promotion.hasOwnProperty('id') ? promotion.id : -1;
        this.description = promotion && promotion.hasOwnProperty('description') ? promotion.description : '';
        this.name = promotion && promotion.hasOwnProperty('name') ? promotion.name : '';
    }

    clone() {
        return Promotion.fromJson(this);
    }

    static create() {
        return Promotion.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Promotion(json);
    }
}
