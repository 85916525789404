import { combineReducers } from 'redux';
import Availability from '../../data/availability';
import * as availabilityActions from '../actions/availabilityActions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities';

export const initialState = {
    availabilityMap: new Map(), // Format: key: restaurantId, value: availability
    remotingMap: new Map(), // Format: key: restaurantId, value: remotingLoad
};

export const availabilityMap = (state = initialState.availabilityMap, action = {}) => {
    const newMap = new Map(state);
    switch (action.type) {
        case availabilityActions.GET_AVAILABILITIES:
            action.restaurantIds.forEach(id => {
                if (newMap.has(id)) {
                    newMap.delete(id);
                }
            });
            return newMap;
        case availabilityActions.GET_AVAILABILITIES_RESPONSE:
            action.response.forEach(a => newMap.set(a.restaurantId, Availability.fromJson(a)));
            return newMap;
        default:
            return state;
    }
};

export const remotingMap = (state = initialState.remotingMap, action = {}) => {
    const newMap = new Map(state);
    switch (action.type) {
        case availabilityActions.GET_AVAILABILITIES:
            action.restaurantIds.forEach(id => newMap.set(id, remotingUtilities.getBusyState()));
            return newMap;
        case availabilityActions.GET_AVAILABILITIES_RESPONSE:
            action.response.forEach(a => newMap.set(a.restaurantId, remotingUtilities.getRemotingState()));
            return newMap;
        case availabilityActions.GET_AVAILABILITIES_ERROR:
            action.restaurantIds.forEach(id => newMap.set(id, remotingUtilities.getErrorState()));
            return newMap;
        default:
            return state;
    }
};

export default combineReducers({
    availabilityMap,
    remotingMap,
});
