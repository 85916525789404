import * as uiActions from '../actions/uiActions';
import * as bookingActions from '../actions/bookingActions';
import * as locationActions from '../actions/locationActions';
import * as restaurantActions from '../actions/restaurantActions';
import * as systemActions from '../actions/systemActions';
import { getConfig } from '../../../../config';

const sendUserEvent = (action, value) =>
    window.dataLayer.push({
        event: 'eventTracking',
        category: 'User',
        action,
        value,
    });

export default store => next => action => {
    if (getConfig().googleTagManagerId) {
        // Track user actions
        switch (action.type) {
            case uiActions.SHOW_SELECT_LOCATION:
                sendUserEvent('clicked-edit-location');
                break;
            case uiActions.SELECT_SLOT:
                sendUserEvent('selected-time-slot');
                break;
            case bookingActions.CREATE_BOOKING:
                sendUserEvent('clicked-create-booking');
                break;
            case uiActions.BOOKING_CONFIRMED:
                sendUserEvent('booking-confirmed');
                break;
            case bookingActions.CANCEL_BOOKING:
                sendUserEvent('clicked-cancel-booking');
                break;
            case bookingActions.CANCEL_BOOKING_RESPONSE:
                sendUserEvent('booking-canceled');
                break;
            case restaurantActions.GET_RESTAURANT:
                sendUserEvent('view-restaurant-details', action.restaurantId);
                break;
            case locationActions.QUERY_FOR_PLACE:
                sendUserEvent('amend-search-location');
                break;
            case systemActions.SET_COVERS:
                sendUserEvent('amend-search-covers', action.covers);
                break;
            case systemActions.SET_DATE:
                sendUserEvent('amend-search-date', action.date);
                break;
            case systemActions.SET_TIME:
                sendUserEvent('amend-search-time', action.time);
                break;
            default:
                break;
        }
    }
    next(action);
};
