export function getRemotingState(busy = false, message = null) {
    return { busy, message };
}

export function resetRemotingState() {
    return getRemotingState();
}

export function getBusyState() {
    return getRemotingState(true);
}

export function getErrorState(error) {
    if (typeof error === 'string') {
        return getRemotingState(false, error);
    }
    if (typeof error === 'object' && error.hasOwnProperty('message')) {
        return getRemotingState(false, error.message);
    }

    return getRemotingState(false, 'unspecified error object');
}
