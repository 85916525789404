export default class Location {
    constructor(location = {}) {
        this.longitude = location.longitude || 0;
        this.latitude = location.latitude || 0;
        this.name = location.name || '';
        this.isDeviceLocation = location.isDeviceLocation || false;
    }

    clone() {
        return Location.fromJson(this);
    }

    toString() {
        let o = {
            longitude: this.longitude,
            latitude: this.latitude,
            name: this.name,
        };
        return JSON.stringify(o);
    }

    static fromDeviceLocation(location) {
        let l = new Location();
        l.longitude = location.coords.longitude;
        l.latitude = location.coords.latitude;
        l.isDeviceLocation = true;
        return l;
    }

    static fromPlace(place, placeDetailsResponse) {
        let l = new Location();
        l.longitude = placeDetailsResponse.result.geometry.location.lng;
        l.latitude = placeDetailsResponse.result.geometry.location.lat;
        l.name = place.getName();
        return l;
    }

    static fromString(string) {
        return Location.fromJson(JSON.parse(string));
    }

    static create() {
        return Location.fromJson();
    }

    static fromJson(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        return new Location(json);
    }
}
