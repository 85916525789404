/*
 * http://www.5thandpenn.com/GeoMaps/GMapsExamples/distanceComplete2.html
 *
 * Calculate geodesic distance (in m) between two points specified by latitude/longitude
 * using Vincenty inverse formula for ellipsoids
 */
export function distVincenty(latLng1, latLng2) {
    let p1 = latLongDegToRad(latLng1);
    let p2 = latLongDegToRad(latLng2);

    let a = 6378137,
        b = 6356752.3142,
        f = 1 / 298.257223563; // WGS-84 ellipsiod
    let L = p2.lon - p1.lon;
    let U1 = Math.atan((1 - f) * Math.tan(p1.lat));
    let U2 = Math.atan((1 - f) * Math.tan(p2.lat));
    let sinU1 = Math.sin(U1),
        cosU1 = Math.cos(U1);
    let sinU2 = Math.sin(U2),
        cosU2 = Math.cos(U2);
    let cosSqAlpha, sigma, sinSigma, cosSigma, cos2SigmaM;

    let lambda = L,
        lambdaP = 2 * Math.PI;
    let iterLimit = 20;
    while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0) {
        let sinLambda = Math.sin(lambda),
            cosLambda = Math.cos(lambda);
        sinSigma = Math.sqrt(
            cosU2 * sinLambda * (cosU2 * sinLambda) +
                (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda)
        );
        if (sinSigma === 0) return 0; // co-incident points
        cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda;
        sigma = Math.atan2(sinSigma, cosSigma);
        let sinAlpha = (cosU1 * cosU2 * sinLambda) / sinSigma;
        cosSqAlpha = 1 - sinAlpha * sinAlpha;
        cos2SigmaM = cosSigma - (2 * sinU1 * sinU2) / cosSqAlpha;
        if (isNaN(cos2SigmaM)) cos2SigmaM = 0; // equatorial line: cosSqAlpha=0 (§6)
        let C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
        lambdaP = lambda;
        lambda =
            L +
            (1 - C) *
                f *
                sinAlpha *
                (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
    }
    if (iterLimit === 0) return NaN; // formula failed to converge

    let uSq = (cosSqAlpha * (a * a - b * b)) / (b * b);
    let A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
    let B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
    let deltaSigma =
        B *
        sinSigma *
        (cos2SigmaM +
            (B / 4) *
                (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
                    (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
    let s = b * A * (sigma - deltaSigma);

    s = s.toFixed(); // round to 1m precision
    return parseInt(s, 10); // return as number;
}

/*
 * LatLong constructor:
 *
 *   arguments are in degrees: signed decimal or d-m-s + NSEW as per LatLong.llToRad()
 */
function latLongDegToRad(latLong) {
    let degLat = latLong.lat;
    let degLong = latLong.lng;
    let lat = llToRad(degLat);
    let lon = llToRad(degLong);
    return { lat, lon };
}

/*
 * convert lat/long in degrees to radians, for handling input values
 *
 *   this is very flexible on formats, allowing signed decimal degrees (numeric or text), or
 *   deg-min-sec suffixed by compass direction (NSEW). A variety of separators are accepted
 *   (eg 3º 37' 09"W) or fixed-width format without separators (eg 0033709W). Seconds and minutes
 *   may be omitted. Minimal validation is done.
 */
export function llToRad(llDeg) {
    let deg;
    if (!isNaN(llDeg)) return (llDeg * Math.PI) / 180; // signed decimal degrees without NSEW

    llDeg = llDeg.replace(/[\s]*$/, ''); // strip trailing whitespace
    let dir = llDeg.slice(-1).toUpperCase(); // compass dir'n
    if (!/[NSEW]/.test(dir)) return NaN; // check for correct compass direction
    llDeg = llDeg.slice(0, -1); // and lose it off the end
    // eslint-disable-next-line no-useless-escape
    let dms = llDeg.split(/[\s:,°º′\'″\"]/); // check for separators indicating d/m/s
    if (dms[dms.length - 1] === '') dms.length--; // trailing separator? (see note below)
    switch (
        dms.length // convert to decimal degrees...
    ) {
        case 3: // interpret 3-part result as d/m/s
            deg = dms[0] / 1 + dms[1] / 60 + dms[2] / 3600;
            break;
        case 2: // interpret 2-part result as d/m
            deg = dms[0] / 1 + dms[1] / 60;
            break;
        case 1: // non-separated format dddmmss
            if (/[NS]/.test(dir)) llDeg = '0' + llDeg; // - normalise N/S to 3-digit degrees
            deg = llDeg.slice(0, 3) / 1 + llDeg.slice(3, 5) / 60 + llDeg.slice(5) / 3600;
            break;
        default:
            return NaN;
    }
    if (/[WS]/.test(dir)) deg = -deg; // take west and south as -ve
    return (deg * Math.PI) / 180; // then convert to radians
}

export function calculateDistanceBetweenPoints(mp1, mp2) {
    let dx = 0;
    let dy = 0;
    if (mp1.hasOwnProperty('x')) {
        dx = Math.abs(mp1.x - mp2.x);
    } else if (mp1.hasOwnProperty('longitude')) {
        dx = Math.abs(mp1.longitude - mp2.longitude);
    }
    if (mp1.hasOwnProperty('y')) {
        dy = Math.abs(mp1.y - mp2.y);
    } else if (mp1.hasOwnProperty('latitude')) {
        dy = Math.abs(mp1.latitude - mp2.latitude);
    }
    return Math.sqrt(dx * dx + dy * dy);
}

export function getClosestIHasLatLongToPoint(iHasLatLngList, iHasLatLong) {
    let a = [];
    let d = Infinity;
    let index = -1;
    let i = 0;
    iHasLatLngList.forEach(item => {
        let distance = calculateDistanceBetweenPoints(item, iHasLatLong);
        if (d > distance) {
            d = distance;
            index = i;
        }
        a.push(distance);
        i++;
    });
    return iHasLatLngList[index];
}
