export const GET_MENUS_FOR_STORE = 'GET_MENUS_FOR_STORE';
export const GET_MENUS_FOR_STORE_RESPONSE = 'GET_MENUS_FOR_STORE_RESPONSE';
export const GET_MENUS_FOR_STORE_ERROR = 'GET_MENUS_FOR_STORE_ERROR';

export const RESET_REMOTING_STATE = 'RESET_REMOTING_STATE_MENU';

export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';

export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';

export const MENU_SELECTED = 'MENU_SELECTED';

export const resetRemotingState = () => {
    return { type: RESET_REMOTING_STATE };
};

export const getMenusForStore = storeId => {
    return { type: GET_MENUS_FOR_STORE, storeId };
};
export const getMenusForStoreResponse = response => {
    return { type: GET_MENUS_FOR_STORE_RESPONSE, response };
};
export const getMenusForStoreError = error => {
    return { type: GET_MENUS_FOR_STORE_ERROR, error };
};

export const menuItemSelected = (menuItem, basketItem) => {
    return { type: MENU_ITEM_SELECTED, menuItem, basketItem };
};

export const categorySelected = category => {
    return { type: CATEGORY_SELECTED, category };
};

export const menuSelected = menu => {
    return { type: MENU_SELECTED, menu };
};
