import { combineReducers } from 'redux';
import ConfirmedBooking from '../../data/confirmedBooking';
import * as bookingActions from '../actions/bookingActions';
import * as remotingUtilities from '../../../../common/utilities/remotingUtilities.js';
import _sortBy from 'lodash/sortBy';
import * as Storage from '../../utilities/storage';

export const initialState = {
    confirmedBooking: null,
    confirmedBookings: [],
    upcomingBookings: [],
    pastBookings: [],
    fetchingExtraReservationFields: false,
    extraReservationFields: [],
    remotingGet: remotingUtilities.getRemotingState(),
    remotingCreate: remotingUtilities.getRemotingState(),
    remotingCancel: remotingUtilities.getRemotingState(),
    preReservationTextVisible: false,
    preReservationConfirmed: false,
};

export const confirmedBooking = (state = initialState.confirmedBooking, action = {}) => {
    switch (action.type) {
        case bookingActions.CREATE_BOOKING:
            return initialState.confirmedBooking;
        case bookingActions.CREATE_BOOKING_RESPONSE:
            const { reservation, guest } = action.response;
            const confirmedBooking = ConfirmedBooking.fromJson({ ...reservation, guest });
            confirmedBooking.setBooking(action.booking);
            return confirmedBooking;
        default:
            return state;
    }
};

export const confirmedBookings = (state = initialState.confirmedBookings, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_BOOKINGS:
            return initialState.confirmedBookings;
        case bookingActions.GET_BOOKINGS_RESPONSE:
            return action.response.map(b => ConfirmedBooking.fromJson(b));
        default:
            return state;
    }
};

export const upcomingBookings = (state = initialState.upcomingBookings, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_BOOKINGS_RESPONSE:
            const upcomingBookings = action.response
                .map(b => ConfirmedBooking.fromJson(b))
                .filter(b => b.reservationState === 0)
                .sort(ConfirmedBooking.sortByDate);

            return _sortBy(upcomingBookings, [b => b.isCancelled]);

        default:
            return state;
    }
};

export const pastBookings = (state = initialState.pastBookings, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_BOOKINGS_RESPONSE:
            const pastBookings = action.response
                .map(b => ConfirmedBooking.fromJson(b))
                .filter(b => b.reservationState === 1)
                .sort(ConfirmedBooking.sortByDate);

            return _sortBy(pastBookings, [b => b.isCancelled]);
        default:
            return state;
    }
};

export const remotingGet = (state = initialState.remotingGet, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_BOOKINGS:
            return remotingUtilities.getBusyState();
        case bookingActions.GET_BOOKINGS_RESPONSE:
            return remotingUtilities.getRemotingState();
        case bookingActions.GET_BOOKINGS_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const remotingCreate = (state = initialState.remotingCreate, action = {}) => {
    switch (action.type) {
        case bookingActions.CREATE_BOOKING:
            return remotingUtilities.getBusyState();
        case bookingActions.CREATE_BOOKING_RESPONSE:
            return remotingUtilities.getRemotingState();
        case bookingActions.CREATE_BOOKING_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const remotingCancel = (state = initialState.remotingCancel, action = {}) => {
    switch (action.type) {
        case bookingActions.CANCEL_BOOKING:
            return remotingUtilities.getBusyState();
        case bookingActions.CANCEL_BOOKING_RESPONSE:
            return remotingUtilities.getRemotingState();
        case bookingActions.CANCEL_BOOKING_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const preReservationTextVisible = (state = initialState.preReservationTextVisible, action = {}) => {
    switch (action.type) {
        case bookingActions.SHOW_PRE_RESERVATION_TEXT:
            return true;
        case bookingActions.HIDE_PRE_RESERVATION_TEXT:
            return false;
        default:
            return state;
    }
};

export const preReservationConfirmed = (state = initialState.preReservationConfirmed, action = {}) => {
    switch (action.type) {
        case bookingActions.PRE_RESERVATION_CONFIRMED:
            return true;
        case bookingActions.PRE_RESERVATION_DECLINED:
        case bookingActions.SHOW_PRE_RESERVATION_TEXT:
            return false;
        default:
            return state;
    }
};

const getFieldLabelFromLocale = (labels, locale) => {
    const label = labels.find(({ culture }) => culture === locale) || labels.find(({ isDefault }) => isDefault) || {};
    return label.value;
};

export const remoting = (state = initialState.remotingGet, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_BOOKINGS:
            return remotingUtilities.getBusyState();
        case bookingActions.GET_BOOKINGS_RESPONSE:
            return remotingUtilities.getRemotingState();
        case bookingActions.GET_BOOKINGS_ERROR:
            return remotingUtilities.getErrorState(action.error);
        default:
            return state;
    }
};

export const extraReservationFields = (state = initialState.extraReservationFields, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_EXTRA_RESERVATION_FIELDS_RESPONSE: {
            const locale = Storage.getLocale();
            return action.response.map(
                ({ key, name, fieldType, showToggle = false, fieldLabels = [], toggleLabels = [], options = [] }) => {
                    const fieldLabel = getFieldLabelFromLocale(fieldLabels, locale);
                    const toggleLabel = getFieldLabelFromLocale(toggleLabels, locale);
                    let initialValue = '';
                    const fieldOptions = options.map(({ option, labels }, index) => {
                        if (index === 0) {
                            initialValue = option;
                        }
                        return {
                            value: option,
                            label: getFieldLabelFromLocale(labels, locale),
                        };
                    });

                    return {
                        initialValue,
                        fieldKey: key,
                        name,
                        fieldLabel,
                        fieldType,
                        showToggle,
                        toggleLabel,
                        fieldOptions,
                    };
                }
            );
        }
        case bookingActions.GET_EXTRA_RESERVATION_FIELDS:
        case bookingActions.GET_EXTRA_RESERVATION_FIELDS_ERROR:
            return initialState.extraReservationFields;
        default:
            return state;
    }
};

export const fetchingExtraReservationFields = (state = initialState.fetchingExtraReservationFields, action = {}) => {
    switch (action.type) {
        case bookingActions.GET_EXTRA_RESERVATION_FIELDS:
            return true;
        case bookingActions.GET_EXTRA_RESERVATION_FIELDS_RESPONSE:
        case bookingActions.GET_BOOKINGS_ERROR:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    confirmedBooking,
    upcomingBookings,
    pastBookings,
    remotingGet,
    remotingCreate,
    remotingCancel,
    preReservationTextVisible,
    preReservationConfirmed,
    extraReservationFields,
    fetchingExtraReservationFields,
});
