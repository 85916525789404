export const INITIALISE_TABLE = 'INITIALISE_TABLE';
export const INITIALISE_TABLE_RESPONSE = 'INITIALISE_TABLE_RESPONSE';
export const INITIALISE_TABLE_ERROR = 'INITIALISE_TABLE_ERROR';

export const START_TABLE = 'START_TABLE';
export const START_TABLE_RESPONSE = 'START_TABLE_RESPONSE';
export const START_TABLE_ERROR = 'START_TABLE_ERROR';

export const JOIN_TABLE = 'JOIN_TABLE';
export const JOIN_TABLE_RESPONSE = 'JOIN_TABLE_RESPONSE';
export const JOIN_TABLE_ERROR = 'JOIN_TABLE_ERROR';

export const SYNCED_TABLE = 'SYNCED_TABLE';
export const LOAD_TABLE = 'LOAD_TABLE';

export const TABLE_CONFIRMED = 'TABLE_CONFIRMED';

export const END_TABLE = 'END_TABLE';
export const END_TABLE_RESPONSE = 'END_TABLE_RESPONSE';
export const END_TABLE_ERROR = 'END_TABLE_ERROR';

export const RESET_REMOTING_STATE = 'RESET_REMOTING_STATE_TABLE';

export const resetRemotingState = () => {
    return { type: RESET_REMOTING_STATE };
};

export const startTable = (userId = -1, storeId = -1) => {
    return { type: START_TABLE, userId, storeId };
};
export const startTableResponse = table => {
    return { type: START_TABLE_RESPONSE, table };
};
export const startTableError = error => {
    return { type: START_TABLE_ERROR, error };
};

export const joinTable = (userId = -1, storeId = -1, posCheckId = -1) => {
    return { type: JOIN_TABLE, userId, storeId, posCheckId };
};

export const joinTableResponse = (table, userId) => {
    return { type: JOIN_TABLE_RESPONSE, table, userId };
};

export const joinTableError = error => {
    return { type: JOIN_TABLE_ERROR, error };
};

export const endTable = (userId, tableId) => {
    return { type: END_TABLE, userId, tableId };
};
export const endTableResponse = response => {
    return { type: END_TABLE_RESPONSE, response };
};
export const endTableError = error => {
    return { type: END_TABLE_ERROR, error };
};

export const syncedTable = (table, user) => {
    return { type: SYNCED_TABLE, table, user };
};

export const loadTable = table => {
    return { type: LOAD_TABLE, table };
};

export const tableConfirmed = tableId => {
    return { type: TABLE_CONFIRMED, tableId };
};
